import React, { Component } from 'react';
import {Row, Col, FormGroup} from 'react-bootstrap';
import { BiInfoCircle } from "react-icons/bi";
import Avatar from '../../../components/avatar/Avatar';
import api from '../../../services/apiCaller';
import Swal from 'sweetalert2';

class PersonalInfoForm extends Component {
    constructor(props){
        super(props);
        this.state={
            agentData: {},
            isLoading: true,
        }
    }

    componentDidMount = () => {
        this.getAgentData();
    }

    getAgentData = async() => {
        const userData = JSON.parse(localStorage.getItem('userData'));
        const fetchData = await api.get(`/agent/${userData.id}`);

        this.setState({
            agentData: fetchData.data,
            isLoading: !this.state.isLoading
        })
    }

    handleChange = (e) => {
        let agentData = this.state.agentData;
        agentData[e.target.name] = e.target.value;
                
        this.setState({
            agentData
        })
    }

    handleEdit = (e) => {
        e.preventDefault();
        let agentData = this.state.agentData;

        // if (this.validate()) {
            this.editAgentDataService(agentData);
        // }
    }

    editAgentDataService = async(agentData) => {
        try {

            const doEditAgentData = await api
                .post(`${process.env.REACT_APP_API_URL}/agent/edit`,
                {
                    name: agentData.name,
                    email: agentData.email,
                    phone: agentData.phone,
                    address: agentData.address,
                });

            localStorage.setItem('userData', JSON.stringify(agentData));
            
            if (doEditAgentData.status === 200) { 
                Swal.mixin({
                    toast: true,
                    position: 'top',
                    showConfirmButton: false,
                    timer: 1500,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                }).fire({
                    icon: 'success',
                    title: 'Profil Berhasil Diubah'
                }).then((res => {
                    if (res) {
                        document.location.reload();
                    }
                }))
            }

            return true;

        } catch (err) {
            // this.setState({
            //     errors: err.response.data
            // })
            
            // return {
            //     error: true,
            //     response: err.response
            // }
        }
    }

    displayAddress = () => {
        return <FormGroup>
                <span style={{ fontSize: 12, opacity: .6 }}>
                    Address
                </span> 
                <br/>
                <span>
                    {this.state.agentData.address ? this.state.agentData.address : "-"}
                </span>
        </FormGroup>
    }

    render(){
        return(
            <>
                <Row>
                    <Col xs={12} className="mb-4">
                        <Avatar/>
                    </Col>
                    <Col xs={12}>
                        <FormGroup>
                            <span style={{ fontSize: 12, opacity: .6 }}>
                                Nama Panjang
                            </span> 
                            <br/>
                            <span>
                                {this.state.agentData.name ? this.state.agentData.name : "-"}
                            </span>
                        </FormGroup>
                    </Col>
                    <Col xs={12} md={6}>
                        <FormGroup>
                            <span style={{ fontSize: 12, opacity: .6 }}>
                                Email
                            </span> 
                            <br/>
                            <span>
                                {this.state.agentData.email ? this.state.agentData.email : "-"}
                            </span>
                        </FormGroup>
                    </Col>
                    <Col xs={12} md={6}>
                        <FormGroup>
                            <span style={{ fontSize: 12, opacity: .6 }}>
                                Phone
                            </span> 
                            <br/>
                            <span>
                                {this.state.agentData.phone ? this.state.agentData.phone : "-"}
                            </span>
                        </FormGroup>
                    </Col>
                    <Col xs={12}>
                        {this.state.agentData && this.displayAddress()}
                    </Col>
                    <Col xs={12} className="align-self-end text-right" style={{ opacity: .6 }}>
                        <BiInfoCircle />
                        <span style={{ fontSize: 12, marginLeft: 5 }}>
                            Hubungi staff kami jika ingin merubah informasi pribadi anda
                        </span>
                    </Col>
                </Row>
            </>
        )
    }
}

export default PersonalInfoForm;