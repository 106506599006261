/**
 * @params {String} Prefix
 * @returns {String} New Generated ID
 */


let lastId = 0;
export default function generatedId(prefix='id') {
    lastId++;
    return `${prefix}${lastId}`;
}