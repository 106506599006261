import React, {useState} from 'react';
import {Card, Row, Col, Button, Form} from 'react-bootstrap';
import {Input, Select, Textarea} from '../../components/form';
import Alert from '../../components/alert/Alert';
import api from '../../services/apiCaller';
import swal from 'sweetalert';

export const ReferralForm = (props) => {
    const [inputs, setInputs] = useState({});
    const [errors, setErrors] = useState();

    const handleInputChange = (event) => {
    
        event.persist();
        if(String(event.target.value).length < 1){
            event.target.classList.add('is-invalid')
        }else{
            event.target.classList.remove('is-invalid')
        }
        setInputs(inputs => ({...inputs, [event.target.name] : event.target.value}));
    
    }

    const handleSubmit = (event) => {
        if (event) {
            event.preventDefault();
        }

        api.post('/referral', inputs)
        .then((res) => {
            if(errors){
                setErrors();
            }
            setInputs({});
            swal({
                position: 'top-end',
                icon: 'success',
                title: 'Data Berhasil Disimpan',
                showConfirmButton: false,
                timer: 1200
            })
            window.location.reload();
        })
        .catch((e) => {
            if(e.response){
                setErrors(e.response.data.errors ? e.response.data.errors[0] : e.response.data.message)
            }
        });
    }

    let errorAlert = errors ? <Alert className="danger">{errors}</Alert> : '';

    return(
        <Form onSubmit={handleSubmit}>
            <Card className='border'>
                <Card.Header>
                    <span className="font-weight-bold text-secondary">Masukan Referral Baru</span>
                </Card.Header>
                <Card.Body>
                    <Row>
                        <Col sm={6}>
                            <Input 
                                type="text"
                                label="Nama Depan"
                                formName="firstName"
                                className={errors ? 'is-invalid' : ''}
                                value={inputs.firstName ? inputs.firstName : ''}
                                onChange={handleInputChange}
                            />
                        </Col>
                        <Col sm={6}>
                            <Input 
                                type="text"
                                label="Nama Belakang"
                                formName="lastName"
                                className={errors ? 'is-invalid' : ''}
                                value={inputs.lastName ? inputs.lastName : ''}
                                onChange={handleInputChange}
                            />
                        </Col>
                        <Col sm={6}>
                            <Input 
                                type="tel"
                                label="Nomor Whatsapp"
                                formName="phone"
                                className={errors ? 'is-invalid' : ''}
                                value={inputs.phone ? inputs.phone : ''}
                                onChange={handleInputChange}
                            />
                        </Col>
                        <Col sm={6}>
                            <Input 
                                type="email"
                                label="Email"
                                formName="email"
                                className={errors ? 'is-invalid' : ''}
                                value={inputs.email ? inputs.email : ''}
                                onChange={handleInputChange}
                            />
                        </Col>
                        <Col className="mt-3" >
                          <Form.Label>Ketertarikan produk</Form.Label>
                          <Form.Control as="select"
                            onChange={handleInputChange}
                            name="productInterest"
                          >
                              <option disabled selected>Pilih Produk</option>
                              <option value="Service Office">Service Office</option>
                              <option value="Virtual Office">Virtual Office</option>
                          </Form.Control>
                        </Col>
                        <Col className="mt-3" >
                          <Form.Label>Location</Form.Label>
                          <Form.Control as="select"
                            onChange={handleInputChange}
                            name="location"
                          >
                              <option disabled selected>Pilih Lokasi</option>
                              <option value="Jakarta Utara">Jakarta Utara</option>
                              <option value="Jakarta Barat">Jakarta Barat</option>
                              <option value="Jakarta Pusat">Jakarta Pusat</option>
                              <option value="Jakarta Timur">Jakarta Timur</option>
                              <option value="Jakarta Selatan">Jakarta Selatan</option>
                              <option value="Tangerang">Tangerang</option>
                              <option value="Bekasi">Bekasi</option>
                              <option value="Bali">Bali</option>
                          </Form.Control>
                        </Col>
                    </Row>
                    {errorAlert}
                    <div className="pt-3 text-right">
                        <Button variant='primary' type="submit" className="px-4">Kirim Data</Button>
                    </div>
                </Card.Body>
            </Card>
        </Form>
    )
}