import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import TotalProfit from './chart/totalProfit';


class ProfitInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    
    render() {
        return(
            <Row>
                <Col lg={12}>
                    <TotalProfit/>
                </Col>
            </Row>
        )
    }
}

export default ProfitInfo;