import React, { Component } from 'react';
import { Card } from 'react-bootstrap';
import { Line } from 'react-chartjs-2';
import api from '../../../../services/apiCaller';
import NumberFormat from 'react-number-format';
import moment from 'moment';

class TotalProfit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chartData: {},
            isLoading: true,
        }
    }

    componentDidMount = () => {
        this.getProfit();
    }

    getProfit = async () => {
        const summaryProfit = await api.get('/profits/summary');

        this.setState({
            chartData: summaryProfit.data,
            isLoading: !this.state.isLoading
        })
    }

    loadChart = (data) => {

        const arr = [];
        const count = {};

        for (let i = 0; i < 12; i++) {
            arr.push(0);
        }

        data.map(v => {
            if (count[moment(v.dateTransfered).month()]) {
                count[moment(v.dateTransfered).month()] += v.amount
            }else{
                count[moment(v.dateTransfered).month()] = v.amount
            }
            return true;
        })

        for (let prop in count){
            if (count[prop] >=0){
                arr[prop] = count[prop]
            }
        }
        
        const dataProfit = () => {
            var chart = document.getElementById('chartSumProfit').getContext('2d'),
            gradient = chart.createLinearGradient(0, 0, 0, 450);
            gradient.addColorStop(0, 'rgba(132, 94, 194, 0.5)');
            gradient.addColorStop(0.5, 'rgba(132, 94, 194, 0.25 )');
            gradient.addColorStop(1, 'rgba(132, 94, 194, 0)');

            return {
                labels: moment.monthsShort(),
                datasets: [
                    {
                    label: 'Keuntungan',
                    data: arr,
                    fill: 'origin',
                    backgroundColor: gradient,
                    borderColor: 'rgba(132, 94, 194, 0.7)',
                    },
                ]
            }
        }

        const options = {
            legend: {
                display: false
            },
            scales: {
                yAxes: [
                    {
                        ticks: {
                            beginAtZero: true,
                            precision: 0
                        },
                    },
                ],
            },
        }

        // console.log(arr, data, count[moment(this.state.chartData.profitsThisYear.dateTransfered).day()]);

        return <Line
                    id='chartSumProfit'
                    data={dataProfit}
                    options={options}
                />
    }
    
    render() {
        return(
            <Card className="border">
                <Card.Body className="mt-2">
                    { !this.state.isLoading && this.loadChart(this.state.chartData.profitsThisYear)}
                    <div className="mt-2">
                        <span>Total Keuntungan Anda</span>
                        <div className="d-flex align-items-baseline">
                            <h3>
                                <NumberFormat 
                                    value={this.state.chartData.total_profits} 
                                    displayType={'text'}
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}
                                />
                            </h3>
                            <h5 className="ml-1">
                                IDR
                            </h5>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        )
    }
}

export default TotalProfit;