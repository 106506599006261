import React, { Component } from 'react';
import {Row, Col, FormGroup} from 'react-bootstrap';
import { BiInfoCircle } from "react-icons/bi";
import Avatar from '../../../components/avatar/Avatar';
import api from '../../../services/apiCaller';
import Swal from 'sweetalert2';


class BankAccountForm extends Component {
    constructor(props){
        super(props);
        this.state={
            agentData: {},
            isLoading: true
        }
    }

    componentDidMount = () => {
        this.getAgentData();
    }

    getAgentData = async() => {
        const userData = JSON.parse(localStorage.getItem('userData'));
        const fetchData = await api.get(`/agent/${userData.id}`);

        this.setState({
            agentData: fetchData.data,
            isLoading: !this.state.isLoading
        })
    }

    handleChange = (e) => {
        let agentData = this.state.agentData;
        agentData[e.target.name] = e.target.value;
        
        this.setState({
            agentData
        })
    }

    handleEdit = (e) => {
        e.preventDefault();
        let agentData = this.state.agentData;

        // if (this.validate()) {
            this.editBankDataService(agentData);
        // }
    }

    editBankDataService = async(agentData) => {
        try {
            const doEditBankData = await api
                .post(`${process.env.REACT_APP_API_URL}/agent/edit`,
                {
                    bankName: agentData.bankName,
                    bankAccount: agentData.bankAccount,
                    bankBranch: agentData.bankBranch,
                })

            localStorage.setItem('userData', JSON.stringify(agentData));
            
            if (doEditBankData.status === 200) { 
                Swal.mixin({
                    toast: true,
                    position: 'top',
                    showConfirmButton: false,
                    timer: 1500,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                }).fire({
                    icon: 'success',
                    title: 'Informasi Bank Berhasil Diubah'
                }).then((res => {
                    if (res) {
                        document.location.reload();
                    }
                }))
            }

            return true;

        } catch (err) {
            this.setState({
                errors: err.response.data
            })
            
            return {
                error: true,
                response: err.response
            }
        }
    }

    render(){
        return(
            <>
                <Row>
                    <Col xs={12} className="mb-4">
                        <Avatar/>
                    </Col>
                    <Col xs={12}>
                        <FormGroup>
                            <span style={{ fontSize: 12, opacity: .6 }}>
                                Bank
                            </span> 
                            <br/>
                            <span>
                                {this.state.agentData.bankName ? this.state.agentData.bankName : '-'}
                            </span>
                        </FormGroup>
                    </Col>
                    <Col xs={12} md={6}>
                        <FormGroup>
                            <span style={{ fontSize: 12, opacity: .6 }}>
                                Akun
                            </span> 
                            <br/>
                            <span>
                                {this.state.agentData.bankAccount ? this.state.agentData.bankAccount : "-"}
                            </span>
                        </FormGroup>
                    </Col>
                    <Col xs={12} md={6}>
                        <FormGroup>
                            <span style={{ fontSize: 12, opacity: .6 }}>
                                Cabang
                            </span> 
                            <br/>
                            <span>
                                {this.state.agentData.bankBranch ? this.state.agentData.bankBranch : "-"}
                            </span>
                        </FormGroup>
                    </Col>
                    <Col xs={12} className="align-self-end text-right" style={{ opacity: .6 }}>
                        <BiInfoCircle />
                        <span style={{ fontSize: 12, marginLeft: 5 }}>
                            Hubungi staff kami jika ingin merubah informasi pribadi anda
                        </span>
                    </Col>
                </Row>
            </>
        )
    }
}

export default BankAccountForm;