import React, {useEffect} from 'react';
import ReactDOM from 'react-dom';
import {FiChevronRight} from 'react-icons/fi';
import {CSSTransition} from 'react-transition-group';
import './sheet.css';

const clientWidth =  window.innerWidth;

export function Sheet(props) {
    
    const nodeRef = React.useRef(null)

    //Add 'sheet-open' class to body tag to prevent scrool behaviour
    if(props.show){
        document.getElementsByTagName('body')[0].classList.add('sheet-open')
    }else{
        document.getElementsByTagName('body')[0].classList.remove('sheet-open')
    }
    
    //Close Sheet on Esc
    const closeOnEscapeKeyDown = (e) => {
        if((e.charCode || e.keyCode) === 27){
            props.handleClose()
        }
    }

    useEffect(() => {
        document.body.addEventListener('keydown', closeOnEscapeKeyDown)
        return () => {
            document.body.removeEventListener('keydown', closeOnEscapeKeyDown)
        }
    }, [])

    return ReactDOM.createPortal(
            <CSSTransition
                in={props.show}
                unmountOnExit
                timeout={{enter: 0, exit: 300}}
                nodeRef={nodeRef}
            >
            <div className={`sheet-container`} ref={nodeRef}>
                <div className="sheet-overlay" onClick={props.handleClose}>
                    <div 
                        className={`sheet ${props.placement}`} 
                        onClick={e => e.stopPropagation()}
                        style={{width: clientWidth > 767 ? props.width : '90%'}}
                    >
                        <button className="btn btn-link text-secondary sheet-close" onClick={props.handleClose}>
                            <FiChevronRight />
                        </button>

                        {props.children}

                    </div>
                </div>
            </div>
        </CSSTransition>
        ,
        document.getElementById('root')
    ) 
    
}

Sheet.Header = function SheetHeader(props){
    return(
        <div className={props.className ? "sheet-header " + props.className : 'sheet-header'}>
            {props.children}
        </div>
    );
}

Sheet.Body = function SheetBody(props){
    return(
        <div className={props.className ? "sheet-body " + props.className : 'sheet-body'}>
            {props.children}
        </div>
    );
}

Sheet.defaultProps = {
    show: false,
    placement: 'right',
    width: clientWidth > 767 ? '50%' : '90%'
}


