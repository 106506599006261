import React, { Component } from 'react';
import { logoutService } from '../../services/authServices';
import { FiLogOut } from "react-icons/fi";
import { CgProfile } from 'react-icons/cg';
import { Link } from 'react-router-dom';
import api from '../../services/apiCaller';
import './header.css';
import Logo from '../../assets/img/logo-ambassador.svg';
import { Container, Nav, Dropdown } from 'react-bootstrap';
import Menu from './Menu';
class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hover: false,
            menuDisplay: 'none',
            centreName: '',
            centreCity: '',
            agentData: {}
        }
    }

    logout = () => {
        const doLogout = logoutService();

        if (doLogout.error) {
            this.setState({
                errorMessage: doLogout.response.data.message
            })
            return false;
        } else {
            return (window.location.href = '/login');
        }
    }

    componentDidMount() {
        this.getAgentData()
    }

    closeMenu = async () => {
        return await this.setState({
            menuDisplay: 'none'
        })
    }

    openMenu = async () => {
        return await this.setState({
            menuDisplay: 'block'
        })
    }

    getAgentData = async () => {
        const userData = JSON.parse(localStorage.getItem('userData'));
        const fetchData = await api.get(`/agent/${userData.id}`);

        this.setState({
            agentData: fetchData.data
        })
    }


    render() {
      const nameUser = this.state.agentData.name
        return(
            <>
                <header className="admin-header" id="navHeader">
                <Container className="d-flex h-100">
                    <nav className="mr-auto my-auto">
                        <Nav className="align-items-center">
                            <Nav.Item className="d-md-inline d-none">
                                <Nav.Link href="foo">
                                    <img alt="logo" src={Logo} width="100px" />
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </nav>
                    <nav className="ml-auto">
                        <Nav className="nav align-items-center">
                            <Dropdown 
                                alignRight={true}
                                className="nav-item" 
                            >
                                <Dropdown.Toggle variant="link" className="nav-link no-arrow px-0" data-toggle="dropdown">
                                    <span className="text-secondary mr-1 d-md-inline d-none">
                                        Hi, <strong>{nameUser}</strong>
                                    </span>
                                    <div className="avatar avatar-sm avatar-online">
                                        <span className="avatar-title rounded-circle bg-dark text-uppercase">
                                            {String(nameUser).charAt(0)}
                                        </span>
                                    </div>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="triangle-right">
                                    <div className="d-flex" style={{ padding: "0.25rem 1.5rem"}}>
                                        <div>
                                            <div className="avatar avatar-sm avatar-online">
                                                <span className="avatar-title rounded-circle bg-dark text-uppercase">
                                                    P
                                                </span>
                                            </div>
                                        </div>
                                        <div>
                                            <span className="font-weight-bold">
                                                {this.state.agentData.name}<br />
                                            </span>
                                            <span>
                                                {this.state.agentData.email}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="dropdown-divider"></div>
                                    {this.state.agentData.status === `1` && <Link to='/accounts/edit/' className="dropdown-item"><span style={{ fontSize: 18, marginRight: 8 }}><CgProfile /></span>Akun Saya</Link>}
                                    <Dropdown.Item className="dropdown-item" href="foo" onClick={this.logout}><span style={{ fontSize: 18, marginRight: 8 }}><FiLogOut /></span>Keluar</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Nav>
                    </nav>
                </Container>
            </header>
            {this.state.agentData ? <Menu agentStatus={this.state.agentData.status} /> : <Menu/>}
            </>
            
        );
    }
}

export default Header;