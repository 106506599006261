import React, { Component } from 'react';
import {Button, Card, FormGroup, Form} from 'react-bootstrap';
import Logo from '../../../assets/img/logo-ambassador.svg';
import Text from '../../../components/text';
import {Input} from '../../../components/form';
import {FiSend, FiAlertCircle} from 'react-icons/fi';
import { Link } from 'react-router-dom';
import api from '../../../services/apiCaller';
import Swal from 'sweetalert2';


class NewPasswordForm extends Component {
    constructor(props){
        super(props)
        this.state={
            agentId: null,
            viewPassword: false,
            input: {},
            errors: {}
        }
    }


    componentDidMount = () => {
        this.checkToken()
    }

    validate = () => {
        let input = this.state.input;
        let errors = {};
        let isValid = true;

        if (!input["newPassword"]) {
            isValid = false;
            errors["newPassword"] = "Tolong isi kolom kata sandi baru."
        }

        if (!input["confirmPassword"]) {
            isValid = false;
            errors["confirmPassword"] = "Tolong isi kolom konfirmasi ulang password."
        }

        if (typeof input["newPassword"] !== "undefined" && typeof input["confirmPassword"] !== "undefined") {
            if (input["newPassword"] !==input["confirmPassword"]) {
                isValid = false;
                errors["confirmPassword"] = "Kata Sandi Tidak Sama.";
            }
        }

        if (input["newPassword"].length < 8) {
            isValid = false;
            errors["newPassword"] = "Kata sandi harus terdiri dari 8 karakter."
        }

        this.setState({
            errors: errors
        })

        return isValid;
    }


    checkToken = async () => {

        try{

            let checkToken = await api
                .post(`${process.env.REACT_APP_API_URL}/forgotPassword/checkToken`,
                {
                    token: this.props.token
                })

            this.setState({
                agentId: checkToken.data.result.agentId
            })

        }catch(e){
            return (document.location.replace('/oops'));
        }
    }
    
    forgotPasswordService = async (newPassword) => {
        try{
            const res = await api
                .put(`${process.env.REACT_APP_API_URL}/forgotPassword`,
                {
                    agentId: this.state.agentId,
                    password: newPassword
                })

            if (res.status === 200) {
                Swal.mixin({
                    // toast: true,
                    position: 'center',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                }).fire({
                    icon: 'success',
                    title: 'Password barhasil diubah.'
                }).then((res => {
                    if (res) {
                        document.location.replace("/login");
                    }
                }))
            }
        }catch(e){
            // console.log(e.response.data);
            
        }
    }

    handleChange = (e) => {
        let input = this.state.input;
        let errors = {};
        input[e.target.name] = e.target.value;
        
        if (typeof input["newPassword"] !== "undefined" && typeof input["confirmPassword"] !== "undefined") {
            if (typeof input["newPassword"] !== "undefined" && typeof input["newPassword"] !== "undefined" && input["newPassword"] !== input["confirmPassword"]) {
                
                errors["confirmPassword"] = "Kata Sandi Tidak Sama.";

            }

            this.setState({
                    errors: errors
                })

        }

        if (input["newPassword"].length < 8) {
                
            errors["newPassword"] = "Kata sandi harus terdiri dari 8 karakter.";
            

            this.setState({
                errors: errors
            })
        }

        
        this.setState({
            input
        })
    }
    
    handleSubmit = async (e) => {
        e.preventDefault();
        
        const input = this.state.input;

        if (this.validate()) {
            this.forgotPasswordService(input["newPassword"]);
        }
        
    }

    handleViewPassword = (e) => {
        this.setState({
            viewPassword: e.target.checked,
        })
    }

    render(){

        let formGroup = {
            marginBottom: "2.5rem",
            position: "relative",
        }
        let error = {
            fontSize: 10,
            position: "absolute",
            bottom: -20
        }
        
        return(
            <div className="row align-items-center min-vh-100 bg-white">
                <div className="col-md-7 col-lg-8 mx-auto">
                    <img alt="logo_vox" src={Logo} className="pb-4 mb-1" />
                    <h4 className="mb-1">
                        Ubah Kata Sandi
                    </h4>
                    <Text className='text-secondary'>Masukkan kata sandi baru anda untuk merubah kata sandi lama anda.</Text>
                    <div className="mt-4">
                        <FormGroup style={formGroup}>
                            <Input
                                type={this.state.viewPassword ? "text" : "password"}
                                label="Masukan Kata Sandi Baru"
                                formName="newPassword"
                                className={(this.state.errors.newPassword ? 'is-invalid' : '')}
                                // value={ this.state.email }
                                onChange={(e) => this.handleChange(e)}
                            />
                            <div className="text-danger" style={error}>{this.state.errors.newPassword && this.state.errors.newPassword}</div>
                        </FormGroup>
                        <FormGroup style={formGroup}>
                            <Input
                                    type={this.state.viewPassword ? "text" : "password"}
                                    label="Masukan Ulang Kata Sandi"
                                    formName="confirmPassword"
                                    className={(this.state.errors.confirmPassword ? 'is-invalid' : '')}
                                    // value={ this.state.email }
                                    onChange={(e) => this.handleChange(e)}
                                />
                            <div className="text-danger" style={error}>{this.state.errors.confirmPassword && this.state.errors.confirmPassword}</div>
                        </FormGroup>
                        <Form.Check type="checkbox" label="Lihat Kata Sandi" name="viewPassword" onChange={(e) => this.handleViewPassword(e)} />
                        <div>
                            {/* { this.state.errorMessage ? this.props.handleAlert( "d-block",this.state.errorMessage ) : this.props.handleAlert("d-none") } */}
                        </div>
                        <div className="d-flex justify-content-between mt-5">
                            <Button className="w-100" variant='primary' onClick={(e) => this.handleSubmit(e)}>
                                <FiSend /> Ganti Kata Sandi
                            </Button>
                        </div>
                        <div className="d-flex justify-content-between my-1">
                            <hr className="w-100" style={{ borderTop: '1px solid rgb(173, 173, 173)' }} />
                        </div>
                        <div className="d-flex justify-content-center align-items-center mt-3">
                            <Link to="/register">Daftar sebagai agen baru</Link>
                        </div>
                        <div className="text-center mt-7">
                            <Card.Text className="small text-secondary"> 
                                <FiAlertCircle /> &nbsp;
                                <Text>
                                    Memiliki masalah saat login?
                                </Text>
                                <a href="mailto:devteam@voffice.co.id" rel="noreferrer"  variant='link' className='btn-sm small' target="_blank">Tanya Developer</a> 
                            </Card.Text>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default NewPasswordForm;