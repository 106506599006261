import React, { Component } from 'react';
import { Card } from 'react-bootstrap';
import { Line } from 'react-chartjs-2';
import moment from 'moment';
import Loader from 'react-loader-spinner';

class ReferralSummary extends Component {

    constructor(props){
        super(props)
        this.state = {
            chartDataRefferal: {},
            isLoading: true,
        }
    }

    componentDidMount = () =>{
        this.setState({
            isLoading: !this.state.isLoading
        })
    }

    loadChart = (data) => { 

        const dataSend = data.referralSendThisYear;
        const dataConfirm = data.referralConfirmedThisYear;
        const dataSuccess = data.referralSuccessThisYear;
        const dataReject = data.referralRejectedThisYear;
        let arrSend = [], arrConfirm = [], arrSuccess = [], arrReject = [];
        let countSend = {}, countConfirm = {}, countSuccess = {}, countReject = {};

        for (let i = 0; i < 12; i++) {
            arrSend.push(0);
            arrConfirm.push(0);
            arrSuccess.push(0);
            arrReject.push(0);
        }
        
        dataSend.map((v) => {
            if (countSend[moment(v.createdAt).month()]) {
                countSend[moment(v.createdAt).month()] += 1
            }else{
                countSend[moment(v.createdAt).month()] = 1
            }
            return true;
        })

        dataConfirm.map((v) => {
            if (countConfirm[moment(v.createdAt).month()]) {
                countConfirm[moment(v.createdAt).month()] += 1
            }else{
                countConfirm[moment(v.createdAt).month()] = 1
            }
            return true;
        })

        dataSuccess.map((v) => {
            if (countSuccess[moment(v.createdAt).month()]) {
                countSuccess[moment(v.createdAt).month()] += 1
            }else{
                countSuccess[moment(v.createdAt).month()] = 1
            }
            return true;
        })

        dataReject.map((v) => {
            if (countReject[moment(v.createdAt).month()]) {
                countReject[moment(v.createdAt).month()] += 1
            }else{
                countReject[moment(v.createdAt).month()] = 1
            }
            return true;
        })

        for (let prop in countSend){
            if (countSend[prop] >=0){
                arrSend[prop] = countSend[prop]
            }
        }

        for (let prop in countConfirm){
            if (countConfirm[prop] >=0){
                arrConfirm[prop] = countConfirm[prop]
            }
        }

        for (let prop in countSuccess){
            if (countSuccess[prop] >=0){
                arrSuccess[prop] = countSuccess[prop]
            }
        }

        for (let prop in countReject){
            if (countReject[prop] >=0){
                arrReject[prop] = countReject[prop]
            }
        }

        const dataSummary = canvas => {
            const ctx = canvas.getContext('2d');
            const gradientRefferalSend = ctx.createLinearGradient(0, 0, 0, 450);
            gradientRefferalSend.addColorStop(0, 'rgba(133, 101, 224, 0.5)');
            gradientRefferalSend.addColorStop(0.5, 'rgba(133, 101, 224, 0.25 )');
            gradientRefferalSend.addColorStop(1, 'rgba(133, 101, 224, 0)');

            const gradientRefferalConfirm = ctx.createLinearGradient(0, 0, 0, 450);
            gradientRefferalConfirm.addColorStop(0, 'rgba(26, 171, 217, 0.5)');
            gradientRefferalConfirm.addColorStop(0.5, 'rgba(26, 171, 217, 0.25 )');
            gradientRefferalConfirm.addColorStop(1, 'rgba(26, 171, 217, 0)');

            const gradientRefferalSuccess = ctx.createLinearGradient(0, 0, 0, 450);
            gradientRefferalSuccess.addColorStop(0, 'rgba(0, 134, 106, 0.5)');
            gradientRefferalSuccess.addColorStop(0.5, 'rgba(0, 134, 106, 0.25 )');
            gradientRefferalSuccess.addColorStop(1, 'rgba(0, 134, 106, 0)');

            const gradientRefferalReject = ctx.createLinearGradient(0, 0, 0, 450);
            gradientRefferalReject.addColorStop(0, 'rgba(229, 74, 95, 0.5)');
            gradientRefferalReject.addColorStop(0.5, 'rgba(229, 74, 95, 0.25 )');
            gradientRefferalReject.addColorStop(1, 'rgba(229, 74, 95, 0)');

            return {
                labels: moment.monthsShort(),
                datasets: [
                    {   
                        type:'line',
                        label: 'Refferal Dikirim',
                        data: arrSend,
                        backgroundColor: gradientRefferalSend,
                        borderColor: 'rgb(133 101 224)',
                    },
                    {   
                        type:'line',
                        label: 'Refferal Terkonfirmasi',
                        data: arrConfirm,
                        backgroundColor: gradientRefferalConfirm,
                        borderColor: 'rgb(26, 171, 217)',
                    },
                    {
                        type:'line',
                        label: 'Referral Sukses',
                        data: arrSuccess,
                        backgroundColor: gradientRefferalSuccess,
                        borderColor: 'rgb(0, 134, 106)'
                    },
                    {
                        type:'line',
                        label: 'Referral Ditolak',
                        data: arrReject,
                        backgroundColor: gradientRefferalReject,
                        borderColor: 'rgb(229, 74, 95)'
                    },
                ]
            }
        }

        const options = {
            tooltips: {
                enabled: false
            },
            legend: {
                position: "top",
                align: "start",
                labels: {
                    usePointStyle: true,
                    boxWidth: 7
                }
            },
            scales: {
                yAxes: [
                    {
                        stacked: false,
                        ticks: {
                            beginAtZero: true,
                            precision: 0
                        },
                    },
                ],
                xAxes: [
                    {
                        stacked: false,
                    },
                ],
            },
        }

        return <Line
                    height={150}
                    data={dataSummary}
                    options={options} />
    }

    showLoader = () => {
        return (
            <div style={{height: 150}} className="text-center pt-4">
                <Loader type="TailSpin" color="#00BFFF" height={80} width={80} />
            </div>
        )
    }

    agentReferral = () => {
                    
        return  <Card className="border">
                    <Card.Body>
                        <Card.Title>Laporan Referral</Card.Title>
                        { Object.keys(this.props.chartData).length !== 0 ? this.loadChart(this.props.chartData) : this.showLoader()}
                    </Card.Body>
                </Card>
    }

    render() {
        return(
            <>  
                {this.agentReferral()}
            </>
        )
    }
}

export default ReferralSummary;