import React, { Component } from 'react';
import { Line } from 'react-chartjs-2'; 
import {Row, Col, Card} from 'react-bootstrap';
import api from '../../../services/apiCaller';
import ReferralSummary from './chart/referralSummary';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import { Link } from 'react-router-dom';

const agentProfit = { label: "Keuntungan Saya", value: 9320115, curency: "IDR" };

class AgentProfit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chartData: {},
            isLoading: true,
        }
    }

    componentDidMount = async() => {
        await this.getProfit();
    }

    getProfit = async () => {
        const fetchData = await api.get(`/profits/summary`);

        this.setState({
            chartData: fetchData.data,
            isLoading: !this.state.isLoading
        })

    }

    loadChart = (data) => {

        const arr = [];
        const count = {};

        for (let i = 0; i < 12; i++) {
            arr.push(0);
        }

        data.map(v => {
            if (count[moment(v.dateTransfered).month()]) {
                count[moment(v.dateTransfered).month()] += v.amount
            }else{
                count[moment(v.dateTransfered).month()] = v.amount
            }
            return true;
        })

        for (let prop in count){
            if (count[prop] >=0){
                arr[prop] = count[prop]
            }
        }
        
        const dataProfit = () => {
            var chart = document.getElementById('chartSumProfit').getContext('2d'),
            gradient = chart.createLinearGradient(0, 0, 0, 450);
            gradient.addColorStop(0, 'rgba(54, 162, 235, 0.5)');
            gradient.addColorStop(0.5, 'rgba(54, 162, 235, 0.25 )');
            gradient.addColorStop(1, 'rgba(54, 162, 235, 0)');

            return {
                labels: moment.monthsShort(),
                datasets: [
                    {
                    label: 'Keuntungan',
                    data: arr,
                    fill: 'origin',
                    backgroundColor: gradient,
                    borderColor: 'rgba(54, 162, 235, 0.7)',
                    },
                ]
            }
        }

        const options = {
            legend: {
                display: false
            },
            elements: {
                point:{
                    radius: 0
                }
            },
            scales: {
                yAxes: [
                    {
                        gridLines: false,
                        ticks: {
                            display: false,
                            beginAtZero: true,
                            precision: 0
                        },
                    },
                ],
                xAxes: [
                    {
                        gridLines: false,
                        ticks: {
                            display: false
                        },
                    },
                ],
            },
        }

        // console.log(arr, data, count[moment(this.state.chartData.profitsThisYear.dateTransfered).day()]);

        return <Line
                    id='chartSumProfit'
                    data={dataProfit}
                    options={options}
                />
    }

    agentProfit = () => {
                    
        return  <Card className="border">
                    <Card.Body>
                        <h6>
                            {agentProfit.label}
                        </h6>
                        <div className="d-flex align-items-baseline">
                            <h1>
                                {(this.state.chartData.total_profits !== undefined) ? 
                                    <NumberFormat 
                                        value={this.state.chartData.total_profits} 
                                        displayType={'text'}
                                        thousandSeparator={'.'}
                                        decimalSeparator={','}
                                    /> : 
                                    this.props.loader}
                            </h1>
                            <h5 className="ml-1">
                                {agentProfit.curency}
                            </h5>
                        </div>
                        <Link to="/profits" className="btn btn-primary mb-3">Lihat Keuntungan</Link>
                        { !this.state.isLoading && this.loadChart(this.state.chartData.profitsThisYear)}
                    </Card.Body>
                </Card>
    }
    
    render() {
        return(
            <Row>
                {/* <Col lg={6} className="mb-4">
                    {this.agentProfit()}
                </Col> */}
                <Col lg={12} className="mb-4">
                    <ReferralSummary chartData={this.props.allReferralData} />
                </Col>
            </Row>
        )
    }
}

export default AgentProfit;