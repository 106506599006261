import React, { Component } from 'react';
import api from '../../services/apiCaller';
import Header from '../../components/header/Header';
import { Container, Button,Alert, Row, Col } from 'react-bootstrap';
import tikamImg from '../../assets/img/titi-kamal-so-600.png';
import tikamImg2 from '../../assets/img/tikamImg2.png';
import {Sheet} from '../../components/Sheet';
import UploadFile from './UploadFile';
import PromoSection from './components/promoSection'
import AgentAchievement from './components/agentAchievement';
import AgentProfit from './components/agentProfit';
import Loader from 'react-loader-spinner';
import Swal from 'sweetalert2';

class Dashboard extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            agentData: {},
            sheetStatus: false,
            referralData: {},
            allReferralData: {},
            agreement: {}
        }
        this.openSheet = this.openSheet.bind(this)
        this.closeSheet = this.closeSheet.bind(this)
    }

    openSheet(){
        this.setState({
            sheetStatus: true
        })
    }

    closeSheet(){
        this.setState({
            sheetStatus: false
        })
    }

    showLoader = () => {
        return (
            <Loader type="TailSpin" color="#00BFFF" height={80} width={80} />
        )
    }

    handleChange(e){
      this.setState({
        agreement: {
          file: e.target.files[0],
          fileName: e.target.files[0].name
        }
      })
    }

    uploadFileService = async (data) => {
      const formData = new FormData();

      formData.append('fileAgreement', data.file);

      try {
          await api.post('agent/upload-paper', formData)
          .then((res) =>{
              Swal.mixin({
                  toast: true,
                  position: 'center',
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                  didOpen: (toast) => {
                      toast.addEventListener('mouseenter', Swal.stopTimer)
                      toast.addEventListener('mouseleave', Swal.resumeTimer)
                  }
              }).fire({
                  icon: 'success',
                  title: 'File terkirim'
              }).then((res => {
                  if (res) {
                      document.location.replace("/dashboard");
                  }
              }))
          })

      }catch(err) {
          console.log(err);
      }
    }

    componentDidMount = async() => {
        await this.getAgentData();
        await this.getaAllReferralData();

    }

    getAgentData = async () => {
        const userData = JSON.parse(localStorage.getItem('userData'));
        const fetchData = await api.get(`/agent/${userData.id}`);

        this.setState({
            agentData: fetchData.data
        })
    }

    getaAllReferralData = async () => {
        const fetchData = await api.get(`/referral/report`);

        this.setState({
            allReferralData: fetchData.data
        })
    }

    showDocumentReview = () => {
        return <Alert variant="success" className="p-0 mx-3 mt-lg-0 mt-0">
                <Row style={{ margin: 0 }}>
                    <Col lg={6} className="p-0 pt-3 d-flex order-md-2">
                        <img src={tikamImg} loading="lazy" alt="tikam" width="100%" height="auto"/>
                    </Col>
                    <Col lg={6} className="p-3 d-flex order-md-1">
                        <div className="my-auto alert-cstm">
                            <Alert.Heading >Dokumen Sedang Diperiksa</Alert.Heading>
                            <p>
                                Terima kasih telah melengkapi proses registrasi, tim kami sedang memeriksa data yang anda kirimkan. Kami akan menghubungi anda kembali secepatnya.
                            </p>
                            <hr />
                            <p>
                                Mengalami masalah dalam mengakses aplikasi? <a href="mailto:cs@voffice.co.id" target="_blank" rel="noreferrer">Hubungi Kami</a>
                            </p>
                        </div>
                    </Col>
                    {/* <div style={{ width: 100, height: 100, fontSize:"2.5rem" }} className="text-white bg-success d-flex justify-content-center align-items-center mx-auto rounded-circle p-3 mb-2">
                        <AiOutlineFileSearch />
                    </div> */}
                </Row>
            </Alert>
    }

    showNeedDocumentUpload = () => {
        return <>
            <Alert variant="danger" className="p-0 w-75 mx-3 mt-lg-0 mt-0">
                <Row style={{ margin: 0 }}>
                    <Col lg={4} className="p-0 d-flex order-md-2">
                        <img src={tikamImg2} loading="lazy" alt="tikam-2" width="100%" height="auto"/>
                    </Col>
                    <Col lg={8} className="p-3 d-flex order-md-1">
                        <div className="my-auto alert-cstm">
                            <Alert.Heading >Kelengkapan Dokumen</Alert.Heading>
                            <p>
                                Mohon untuk melengkapi data anda untuk menyelesaikan proses registrasi.
                            </p>
                            <Button variant="danger" className="mt-2" onClick={this.openSheet}>
                                Lengkapi Data Sekarang
                            </Button>
                        </div>
                    </Col>
                    {/* <div style={{ width: 100, height: 100, fontSize:"2.5rem" }} className="text-white bg-success d-flex justify-content-center align-items-center mx-auto rounded-circle p-3 mb-2">
                        <AiOutlineFileSearch />
                    </div> */}
                </Row>
            </Alert>
            <Sheet 
                show={this.state.sheetStatus} 
                handleClose={this.closeSheet}
                width='40%'
            >
                <Sheet.Header className="font-weight-bold">
                    Upload Identitas
                </Sheet.Header>
                <Sheet.Body>
                    <UploadFile agentData={this.state.agentData && this.state.agentData} />
                </Sheet.Body>
            </Sheet>
        </>
    }

    showDashboard = () => {
        return (<>
            <h5 className="mb-4">Dashboard</h5>
            <PromoSection />
            <AgentAchievement referralData={this.state.allReferralData && this.state.allReferralData} loader={this.showLoader()} />
            <AgentProfit allReferralData={(this.state.allReferralData) && this.state.allReferralData} loader={this.showLoader()}/>
        </>)
    }

    showUploadAgreement = () => {
        return <Alert variant="info" className="p-0 mx-3 mt-lg-0 mt-0">
                <Row style={{ margin: 0 }}>
                    <Col lg={6} className="p-0 pt-3 d-flex order-md-2">
                        <img src={tikamImg} loading="lazy" alt="tikam" width="100%" height="auto"/>
                    </Col>
                    <Col lg={6} className="p-3 d-flex order-md-1">
                        <div className="my-auto alert-cstm">
                            <Alert.Heading >Upload Agreement</Alert.Heading>
                            <p>
                              Kami telah mengirimkan agreement ke alamat email anda, silahkan upload agreement setelah anda menandatanganinya.
                            </p>
                            <hr />
                              <div className="input-group">
                                <div className="custom-file custom">
                                  <input type="file" 
                                    className="custom-file-input" 
                                    aria-describedby="inputGroupFileAddon04" 
                                    accept="application/pdf"
                                    onClick={(e) => {
                                      e.target.value = null;
                                    }}
                                    onChange={(e) => this.handleChange(e)}
                                  />
                                  <label className="custom-file-label" for="inputGroupFile04">Choose file</label>
                                </div>
                              </div>
                              <div className={`form-group mt-2 ${Object.keys(this.state.agreement).length === 0 && 'd-none'}`}>
                                <label>Your Agreement</label>
                                <div className="row m-0 justify-content-md-between border-dark">
                                  <div className="col-xs-12">
                                    <span className="my-auto">{this.state.agreement.fileName}</span>
                                  </div>
                                  <div className="col-xs-12">
                                    <button
                                      className="btn btn-primary"
                                      onClick={()=>this.uploadFileService(this.state.agreement)}
                                    >
                                      Upload
                                    </button>
                                    <button className="btn btn-link" onClick={() => {
                                      this.setState({
                                        fileName: null
                                      })
                                    }}>
                                      Cancle
                                    </button>
                                  </div>
                                </div>
                              </div>
                        </div>
                    </Col>
                    {/* <div style={{ width: 100, height: 100, fontSize:"2.5rem" }} className="text-white bg-success d-flex justify-content-center align-items-center mx-auto rounded-circle p-3 mb-2">
                        <AiOutlineFileSearch />
                    </div> */}
                </Row>
            </Alert>
    }

    render() {
        return (
            <>
                <Header />
                <Container className={this.state.agentData.status === "2" || this.state.agentData.status === "3" || this.state.agentData.status === "5"? "h-75 d-flex justify-content-center align-items-center" : ""} style={{ marginTop: 45 }}>
                    {this.state.agentData.status === "1" && this.showDashboard()}
                    {this.state.agentData.status === "2" && this.showNeedDocumentUpload()}
                    {this.state.agentData.status === "3" && this.showDocumentReview()}
                    {this.state.agentData.status === "5" && this.showUploadAgreement()}
                </Container>
            </>
        );
    }
}

export default Dashboard;