import React, { Component } from 'react';
import { ListGroup } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const menuAgentProfile = [
    {label:'Informasi Personal', url:'/accounts/edit/'},
    {label:'Akun Bank', url:'/accounts/bank/'},
    {label:'Ubah Kata Sandi', url:'/accounts/password/'},
];

class SideMenuProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            
        }
    }
    
    handleClick = (menuItem) => { 
        this.setState({ active: menuItem });
    }

    render(){

        return (
            <ListGroup className="rounded-0">
                {menuAgentProfile.map(menuItem => <NavLink
                        onClick={this.handleClick.bind(this, menuItem)}
                        className="list-group-item list-group-item-action border-0"
                        to={menuItem.url}
                        key={menuItem.label}>
                        {
                            menuItem.label
                        }
                    </NavLink>
                )}
            </ListGroup>
        )
    }

}

export default SideMenuProfile;
