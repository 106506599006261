import React, { Component } from 'react';
import { Form } from 'react-bootstrap';

class Avatar extends Component {
    constructor(props){
        super(props);
        this.state={
            agentData: {},
            isLoading: true
        }
        this.agentData = JSON.parse(localStorage.getItem("userData"));
    }

    writeInitialAgent = (agentName) => {
        if (agentName !== undefined) {
            const name = agentName.split(" ");
            let initialName = "";

            name.map((arrName) => {
                initialName += arrName.charAt(0).toUpperCase()
                return true;
            })

            return initialName
        }
    }

    render() {
        return(
            <div className="d-flex">
                <div 
                    style={{ width: 40, height: 40 }} 
                    className="bg-dark d-flex justify-content-center align-items-center rounded-circle text-white">
                        {
                            this.writeInitialAgent(this.agentData.name)
                        }
                </div>
                <Form.Label className="ml-2 my-auto">
                    {this.agentData.name}
                </Form.Label>
            </div>
        )
    }
}

export default Avatar;