import React, { Component } from 'react';
import Logo404 from './../assets/img/404-logo.svg';
import { Link } from 'react-router-dom';


class Page404 extends Component {
    constructor(props){
        super(props)
        this.state={
        }
    }

    componentDidMount = () => {
    }

    render() {
        return (
            <div className="container-fluid d-flex justify-content-center align-items-center h-100">
                <div className="text-center">
                    <h5>Oops, sepertinya anda tersesat</h5>
                    <p className="mb-5">Halaman yang anda cari tidak ditemukan.</p>
                    <Link className="btn btn-primary mx-auto mb-5" to="/login">Kembali ke Halaman Masuk</Link>
                    <img alt="logo_vox" src={Logo404} className="pb-4 mb-1 mt-4" style={{ height: "45vh", opacity: .5, display: "block" }} />
                </div>
            </div>
        );
    }
}

export default Page404;