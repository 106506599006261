import React from 'react';
import Header from '../../components/header/Header';
import { Container } from 'react-bootstrap';
import { ReferralForm } from './form';
import DataTable from '../../components/DataTable/DataTable';

import './referral.css';
class Referrals extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            agentData: {},
        }
        this.renderTable = this.renderTable.bind(this)
    }
    
    // getAgentData = async () => {
    //     const userData = JSON.parse(localStorage.getItem('userData'));
    //     const fetchData = await api.get(`/agent/${userData.id}`);
        
    //     this.setState({
    //         agentData: fetchData.data
    //     })
        
    // }
    
    componentDidMount(){
    }

    renderTable(){

        let tableAttributes = [
            {label: 'Nama', name: ''},
            {label: 'Status', name: 'status', transform: (data) => {

                let status;
                switch (data) {
                    case '0':
                        status = 'Uncontacted'
                        break;
                    case '1':
                        status = 'Contacted'
                        break;
                    case '2':
                        status = 'No Response'
                        break;
                    case '3':
                        status = 'Not Interested'
                        break;
                    case '4':
                        status = 'Transaction In Process'
                        break;
                    case '5':
                        status = 'Unsuccessful'
                        break;
                    case '6':
                        status = 'Rejected'
                        break;
                    case '7':
                        status = 'Success'
                        break;
                    case '8':
                        status = 'Confirmed'
                        break;
                    default:
                        status = '-'
                        break;
                }

                return status;
            }},
            // {label: 'Status Pembayaran', name: 'paymentStatus', transform: (data) => {
            //     return data === 1 ? <Badge pill variant="success">Paid</Badge> : <Badge pill variant="warning">Unpaid</Badge>;
            // }},
            // {label: 'Keuntungan', name: 'profit', transform: (data) => {
            //     return <>  
            //                 <NumberFormat 
            //                     value={data} 
            //                     displayType={'text'}
            //                     thousandSeparator={'.'}
            //                     decimalSeparator={','}
            //                 />
            //                 <span>
            //                     &nbsp; IDR
            //                 </span>
            //             </>
            // }},
            {label: '',name: '', transform: (data) => {
                return true;
            }}
        ];

        return (
            <DataTable apiTarget='/referral' attributes={tableAttributes} />
        )
    }
    
    render() {
        return(
            <>
                <Header />
                <Container className="pb-5 pt-0" style={{ marginTop: 45 }}>
                    <h5>Referral</h5>
                    <ReferralForm />

                </Container>
                <section className="py-2">
                    <Container>
                        <h5 className="mt-7">Riwayat Pengiriman Referral</h5>
                        {this.renderTable()}
                    </Container>
                </section>
            </>
        )
    }
}

export default Referrals;