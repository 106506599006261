import React, { Component } from 'react';
import DataTablePayout from './components/dataTablePayout';
import ProfitInfo from './components/profitInfo';
import Header from '../../components/header/Header';
import { Container } from 'react-bootstrap';

class Profits extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    showProfit = () => {
        return <>
            <ProfitInfo />
            <DataTablePayout />
        </>
    }
    
    render() {
        return(
            <>
                <Header />
                <Container style={{ marginTop: 45 }}>
                    {this.showProfit()}
                </Container> 
            </>
        )
    }
}

export default Profits;