import React, { Component } from 'react';
import Text from './../../components/text';
import {Button, Alert, Card, Form} from 'react-bootstrap';
import {Input} from '../../components/form';
import BgLogin from './../../assets/img/imgLogin.jpg';
import Logo from './../../assets/img/logo-ambassador.svg';

import {FiLogIn, FiAlertCircle} from 'react-icons/fi';
import { loginService } from '../../services/authServices';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

class Login extends Component {
    constructor(props){
        super(props);
        this.state = {
            email: '',
            password: '',
            errorMessage: '',
            viewPassword: false,
            error: false
        }
    }

    getGreeting = () => {
        var e = new Date().getHours();

        return e < 12
        ? "Selamat Pagi"
        : e < 16
        ? "Selamat Siang"
        : e < 18 
        ? "Selamat Sore"
        : "Selamat Malam";
    }

    handleViewPassword = (e) => {
        this.setState({
            viewPassword: e.target.checked,
        })
    }

    handleChange = (e) => {
        this.setState({
            [e.target.type]:e.target.value
        })
    }
    
    handleSubmit = (e) => {
        e.preventDefault();
        
        this.handleLogin(this.state.email, this.state.password);
    }
    
    handleLogin = async (email, password) => {
        const doLogin = await loginService(email, password);

        if(doLogin.error || doLogin.response) {

            this.setState({
                errorMessage: doLogin.response,
                error: true
            })

            return Swal.mixin({
                    // toast: true,
                    position: 'center',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                }).fire({
                    icon: 'warning',
                    title: "Oops",
                    text: this.state.errorMessage,
                });
        }

        return (window.location.href = '/dashboard');
        
    }

    handleForgot = async (e) => {
        e.preventDefault();

        return (window.location.href = '/forgot-password');
    }

    handleAlert = ( style, message ) => {
        return <Alert variant="warning" className={style}>{message}</Alert>
    }
    
    render() {
        const bgLogin = {
            background: 'url(' + BgLogin + ') no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            boxShadow: 'inset 0px 4px 15px rgba(0, 0, 0, 0.25)'
        }

        return(
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-7 d-none d-md-block bg-cover bg-primary" style={bgLogin}>
    
                    </div>
                    <div className="col-lg-5">
                        <div className="row align-items-center min-vh-100 bg-white">
                            <div className="col-md-7 col-lg-8 mx-auto">
                                <img alt="logo_vox" src={Logo} className="pb-4 mb-1" />
                                <h4 className="mb-1">
                                    {this.getGreeting()},
                                </h4>
                                <Text className='text-secondary'>Silahkan masuk ke akun anda</Text>
                                <div className="mt-4">
                                    <Input 
                                        type="email"
                                        label="Email"
                                        formName="email"
                                        className={(this.state.error ? 'is-invalid' : '')}
                                        // value={this.state.email}
                                        onChange={(e) => this.handleChange(e)}
                                    />
                                    <Input
                                        type={this.state.viewPassword ? "text" : "password"}
                                        label="Kata sandi"
                                        formName="password"
                                        className={(this.state.error ? 'is-invalid' : '')}
                                        // value={this.state.password}
                                        onChange={(e) => this.handleChange(e)}
                                    />
                                    <Form.Check type="checkbox" label="Lihat Kata Sandi" name="viewPassword" onChange={(e) => this.handleViewPassword(e)} />
                                    <div className="d-flex justify-content-between mt-5">
                                        <Button className="w-100" variant='primary' onClick={(e) => this.handleSubmit(e)}>
                                            <FiLogIn /> Masuk
                                        </Button>
                                    </div>
                                    {/* <Row className="mt-1">
                                        <Col xs={12} md={12}>
                                            <Link className="btn btn-outline-primary w-100 my-1" to="/register">Daftar sebagai individu</Link>
                                        </Col>
                                        <Col xs={12} md={12}>
                                            <Link className="btn btn-outline-primary w-100 my-1" to="/register/corporate">Daftar sebagai corporate</Link>
                                        </Col>
                                    </Row> */}
                                    <div className="d-flex justify-content-between my-1">
                                        <hr className="w-100" style={{ borderTop: '1px solid rgb(173, 173, 173)' }} />
                                    </div>
                                    <div className="text-center">
                                        <Link to="/password/forgot">Lupa Password</Link>
                                    </div>
                                    <div className="d-flex justify-content-center align-items-center mt-3">
                                        <span>Tidak memiliki akun? <Link to="/register">Daftar sebagai agen baru</Link></span>
                                    </div>
                                    <div className="text-center mt-7">
                                        <Card.Text className="small text-secondary"> 
                                            <FiAlertCircle /> &nbsp;
                                            <Text>
                                                Memiliki masalah saat login?
                                            </Text>
                                            <a 
                                              href="#" 
                                              rel="noreferrer"  
                                              variant='link' 
                                              className='btn-sm small' 
                                              target="_blank"
                                              onClick={(e) => {
                                                window.location = 'mailto:devteam@voffice.co.id';
                                                e.preventDefault();
                                              }}
                                            >Tanya Developer</a> 
                                        </Card.Text>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Login;