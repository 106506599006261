import React, { Component } from 'react';
import Swal from 'sweetalert2';

import { Input } from '../../components/form';
import { Button, Form, FormGroup, Row, Col } from 'react-bootstrap';
import api from '../../services/apiCaller';


class ChangePassword extends Component {
    constructor(props){
        super(props);
        this.state = {
            viewPassword: false,
            input: {},
            errors: {},
            // errorMessage: '',
            // error: false
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.changePasswordService = this.changePasswordService.bind(this);

    }

    handleChange = (e) => {
        let input = this.state.input;
        let errors = {};
        input[e.target.name] = e.target.value;
        
        if (typeof input["newPassword"] !== "undefined" && typeof input["confirmNewPassword"] !== "undefined") {
            if (typeof input["newPassword"] !== "undefined" && typeof input["newPassword"] !== "undefined" && input["newPassword"] !== input["confirmNewPassword"]) {
                errors["confirmNewPassword"] = "New password confirmation doesn't match";
            }

            this.setState({
                    errors: errors
                })
        }
        
        this.setState({
            input
        })
    }

    handleViewPassword = (e) => {
        this.setState({
            viewPassword: e.target.checked,
        })
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        const input = this.state.input;
        const userData = JSON.parse(localStorage.getItem("userData"));

        if (this.validate()) {
            this.changePasswordService(userData.id, input["oldPassword"], input["newPassword"]);
        }

        // if(doChangePassword.error) {
        //     this.setState({
        //         errorMessage: doChangePassword.response.data.message,
        //         error: true
        //     })
        //     return false;
        // } else {
        //     // return (window.location.href = '/change-password');
        // }
    }

    validate = () => {
        let input = this.state.input;
        let errors = {};
        let isValid = true;

        if (!input["oldPassword"]) {
            isValid = false;
            errors["oldPassword"] = "Please enter your old password."
        }

        if (!input["newPassword"]) {
            isValid = false;
            errors["newPassword"] = "Please enter your new password."
        }

        if (!input["confirmNewPassword"]) {
            isValid = false;
            errors["confirmNewPassword"] = "Please enter your confirm new password."
        }

        if (typeof input["newPassword"] !== "undefined" && typeof input["confirmNewPassword"] !== "undefined") {
            if (input["newPassword"] !==input["confirmNewPassword"]) {
                isValid = false;
                errors["confirmNewPassword"] = "New password confirmation doesn't match.";
            }
        }

        this.setState({
            errors: errors
        })

        return isValid;
    }

    changePasswordService = async (userId ,oldPassword, newPassword) => {
        try {

            const doChangePassword = await api
                .post(`${process.env.REACT_APP_API_URL}/changePassword`,
                {
                    agentId: userId,
                    oldPassword: oldPassword,
                    newPassword: newPassword
                })
            
            if (doChangePassword.status === 200) {
                Swal.mixin({
                    toast: true,
                    position: 'top',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                }).fire({
                    icon: 'success',
                    title: 'Berhasil mengganti kata sandi'
                }).then((res => {
                    if (res) {
                        document.location.replace("/dashboard");
                    }
                }))
            }

            return true;

        } catch (err) {
            this.setState({
                errors: err.response.data
            })
            
            return {
                error: true,
                response: err.response
            }
        }
    }

    render() {
        let error = {
            fontSize: 10,
            position: "absolute",
            bottom: -20
        }

        return(
            <Row>
                <Col xs={12}>
                    <FormGroup>
                        <Input 
                            type={this.state.viewPassword ? "text" : "password"}
                            label="Kata Sandi Lama"
                            formName="oldPassword"
                            onChange={(e) => this.handleChange(e)}
                        />
                        <div className="text-danger" style={error}>{this.state.errors.oldPassword ? this.state.errors.oldPassword : this.state.errors.message}</div>
                    </FormGroup>
                </Col>
                <Col xs={12} md={6}>
                    <FormGroup>
                        <Input 
                            type={this.state.viewPassword ? "text" : "password"}
                            label="Kata Sandi Baru"
                            formName="newPassword"
                            onChange={(e) => this.handleChange(e)}
                        />
                        <div className="text-danger" style={error}>{this.state.errors.newPassword}</div>
                    </FormGroup>
                </Col>
                <Col xs={12} md={6}>
                    <FormGroup>
                        <Input 
                            type={this.state.viewPassword ? "text" : "password"}
                            label="Masukan Ulang Kata Sandi"
                            formName="confirmNewPassword"
                            onKeyUp={(e) => this.handleChange(e)}
                        />
                        <div className="text-danger" style={error}>{this.state.errors.confirmNewPassword}</div>
                    </FormGroup>
                </Col>
                <Col xs={12}>
                    <Form.Group className="mt-3">
                        <Form.Label className="mb-5" style={{ opacity:0.5 }}>
                            Gunakan nomor atau simbol untuk membuat password anda lebih aman
                        </Form.Label>
                        <Form.Check type="checkbox" label="Lihat Kata Sandi" name="viewPassword" onChange={(e) => this.handleViewPassword(e)} />
                    </Form.Group>
                </Col>
                <Col xs={12} className="text-right">
                    <Button
                        variant='primary'
                        onClick={this.handleSubmit}
                        className="mt-5"
                        >
                            Ubah    
                    </Button>
                </Col>
        </Row>
        )
    }
}

export default ChangePassword;