import React, {
    Component
} from 'react';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import Logo from './../../../assets/img/logo-ambassador.svg';
import {Input} from '../../../components/form';
import { Link } from 'react-router-dom';
import api from '../../../services/apiCaller';
import swal from 'sweetalert';
import Swal from 'sweetalert2';
import debounce from 'lodash.debounce';

class Register extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                isCompany: '0'
            },
            confirmPassword: "",
            viewPassword: false,
            bank: []
        }
    }

    debounceConfirmPassword = debounce(async (value) => {
        this.setState({
            confirmPassword: value
        })
    }, 500)

    handleViewPassword = (e) => {
        this.setState({
            viewPassword: e.target.checked,
        })
    }

    handleChange = async (e) =>{
        const isNoSales = e.target.name === "isNoSales";
        const data = isNoSales ? e.target.checked : e.target.value;
        const target = isNoSales ? "isNoSales" : e.target.name;
        
        if(e.target.name === "confirmPassword") return this.debounceConfirmPassword(e.target.value)

        return await this.setState({
            data: {
                ...this.state.data,
                [target]: data,
            }
        })
    }

    doRegister = async () => {

        console.log('masuk');
        console.log(this.state);

        if(this.state.data.password !== this.state.confirmPassword) return swal("Oops", "Konfirmasi password anda masih tidak cocok", "error");

        try{
            if (this.state.data.isNoSales) {
        
                await this.setState({
                    data: {
                        ...this.state.data,
                        ["salesID"]: "9023",
                    }
                })
            }

            console.log('lewaat');

            const agentRegisterServices = await api.post('/register', this.state.data);

            console.log(agentRegisterServices);

            if (agentRegisterServices.status === 200) {
                Swal.mixin({
                    // toast: true,
                    position: 'center',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                }).fire({
                    icon: 'success',
                    title: 'Akun Berhasil Dibuat',
                    text: 'Periksa Email anda untuk melakukan verifikasi akun'
                }).then((res => {
                    if (res) {
                        document.location.replace("/login");
                    }
                }))
            }
        }catch(err){
            if(err){
                console.log(err)
                const parsedError = err.response.data;
                return swal("Oops", parsedError.errors ? parsedError.errors[0] : parsedError.message, "error");
            }
        }
    }

    loadBankList = () => {
        (Object.values(this.state.bank)).map(bank => {
            return <option>{JSON.stringify(bank)}</option>
        })
    }

    componentAgentInCharge = () => {
      return (<Row>
                    <Col sm={12}>
                        <Form.Control as="select"
                            className="mb-5"
                            onChange={(e) => this.handleChange(e)}
                            name="salesID"
                        >
                            <option disabled selected>Pilih Sales</option>
                            <option value="37">Wilma Balqis</option>
                            <option value="54">Astrid Lydia</option>
                            <option value="186">Dini Desfaty</option>
                            <option value="440">Lia</option>
                            <option value="9006">Dheana Septia</option>
                            <option value="455">Reni Serawati</option>
                            <option value="9023">Randy Nasir</option>       
                            <option value="9111">Yunice Marisa</option>
                        </Form.Control>
                    </Col>
                </Row>);
    }

    render() {
        return (
            <Container className="py-5">
                <Row className="my-5">
                    <Col className="text-center">
                        <img alt="logo_vox" src={Logo} className="pb-4 mb-1" />
                    </Col>
                    <Col sm={12}>
                        <h3>Jadilah Rekan Bisnis Kami</h3>
                        <span>Bantu sesama entrepreneur dan bisnis lainnya berkembang. Beri tahu rekan Anda mengenai solusi bisnis pintar vOffice dan temukan cara baru dan mudah untuk mendapat imbalan yang besar!</span>
                    </Col>
                    <Col sm={12} className="mt-4">
                        <b>Anda agent corporate?  </b><Link to="/register/corporate">Daftar disini</Link>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} className="mt-3 mb-3 ">
                        <h6 className="font-weight-lighter">Sales</h6>
                        <input type="checkbox" name="isNoSales" onChange={e => this.handleChange(e)} value="true" /> <label>Tidak ada sales</label>
                    </Col>
                </Row>
                {!this.state.data.isNoSales && this.componentAgentInCharge()}
                <Row>
                    <Col sm={12} className="mt-3">
                        <h6 className="font-weight-lighter">Informasi Akun</h6>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} lg={12}>
                        <Input
                            type="email"
                            label="Email"
                            formName="email"
                            className={(this.state.error ? 'is-invalid' : '')}
                            value={this.state.email}
                            onChange={(e) => this.handleChange(e)}
                        />
                    </Col>
                    <Col sm={12} lg={6}>
                        <Input
                            type={this.state.viewPassword ? "text" : "password"}
                            label="Kata sandi"
                            formName="password"
                            className={(this.state.error ? 'is-invalid' : '')}
                            value={this.state.password}
                            onChange={(e) => this.handleChange(e)}
                        />
                        <Form.Check type="checkbox" label="Lihat Kata Sandi" name="viewPassword" onChange={(e) => this.handleViewPassword(e)} />
                    </Col>
                    <Col sm={12} lg={6}>
                        <Input
                            type={this.state.viewPassword ? "text" : "password"}
                            label="Konfirmasi kata sandi"
                            formName="confirmPassword"
                            className={(this.state.error || (this.state.data.password !== this.state.confirmPassword && this.state.confirmPassword )? 'is-invalid' : '')}
                            value={this.state.password}
                            onChange={(e) => this.handleChange(e)}
                        />
                        {
                                this.state.data.password !== this.state.confirmPassword && this.state.confirmPassword ? 
                                <small className="text-danger">Konfirmasi password anda tidak cocok</small> : ''
                        }
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} className="mt-3">
                        <h6 className="font-weight-lighter">Data Pribadi</h6>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <Input
                            type="text"
                            label="Nama"
                            formName="name"
                            className={(this.state.error ? 'is-invalid' : '')}
                            value={this.state.name}
                            onChange={(e) => this.handleChange(e)}
                        />
                    </Col>
                    <Col sm={12}>
                        <Input
                            type="text"
                            label="Jabatan"
                            formName="position"
                            className={(this.state.error ? 'is-invalid' : '')}
                            value={this.state.name}
                            onChange={(e) => this.handleChange(e)}
                        />
                    </Col>
                    <Col sm={12}>
                        <Input
                            type="text"
                            label="No Whatsapp"
                            formName="phone"
                            className={(this.state.error ? 'is-invalid' : '')}
                            value={this.state.phone}
                            onChange={(e) => this.handleChange(e)}
                        />
                    </Col>
                </Row>
                <Row className="my-3">
                    <Col>
                        <Button className="w-100" onClick={e => this.doRegister(e)}>Daftar Sekarang</Button>
                    </Col>
                </Row>
                <Row className="my-2">
                    <Col className="text-center">
                        <Link to="/login">Kembali Ke Halaman Masuk</Link>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default Register;