import React, { Component } from 'react';
import {Row, Col, FormGroup, Button, Form} from 'react-bootstrap';
import Avatar from '../../../components/avatar/Avatar';


class ContractInfoForm extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render(){
        return(
            <>
                <Row>
                    <Col xs={12}>
                        <Avatar/>
                    </Col>
                    <Col xs={12} md={4} className="mb-2 mt-3">
                        <FormGroup>
                            <Form.Label className="d-block" style={{ fontSize: 12 }}>
                                Masa <br className="d-block d-lg-none" /> Kontrak
                            </Form.Label>
                            <span>
                                12
                            </span>
                        </FormGroup>
                    </Col>
                    <Col xs={12} md={4} className="mb-2 mt-3">
                        <FormGroup>
                            <Form.Label className="d-block" style={{ fontSize: 12 }}>
                                Tanggal Mulai Kontrak
                            </Form.Label>
                            <span>
                                2020-01-01
                            </span>
                        </FormGroup>
                    </Col>
                    <Col xs={12} md={4} className="mb-2 mt-3">
                        <FormGroup>
                            <Form.Label className="d-block" style={{ fontSize: 12 }}>
                                Tanggal Berakhir Kontrak
                            </Form.Label>
                            <span>
                                2021-01-01
                            </span>
                        </FormGroup>
                    </Col>
                    <Col xs={12}>
                        <Button variant="outline-primary" className="mb-lg-0 mb-2 mr-2">
                            Lihat Agreement
                        </Button>
                        <Button variant="outline-primary" className="mb-lg-0 mb-2 mr-2">
                            Lihat KTP
                        </Button>
                        <Button variant="outline-primary" className="mb-lg-0 mb-2 mr-2">
                            Lihat NPWP
                        </Button>
                    </Col>
                </Row>
            </>
        )
    }
}

export default ContractInfoForm;