import React, { Component } from 'react';
import DataTable from '../../../components/DataTable/DataTable'
import NumberFormat from 'react-number-format';

class DataTablePayout extends Component {
    constructor(props){
        super(props);
        this.state = {
            tableAttributes: {},
        }
    }
    
    renderTable(){
        
        let tableAttributes = [
            {label: 'Akun Bank', name: ''},
            {label: 'Tanggal Tranfer', name: 'dateTransfered'},
            {label: 'Jumlah', name: 'amount', transform: (data) => {
                return <>  
                            <NumberFormat 
                                value={data} 
                                displayType={'text'}
                                thousandSeparator={'.'}
                                decimalSeparator={','}
                            />
                            <span>
                                &nbsp; IDR
                            </span>
                        </>
            }},
        ];

        return (
            <DataTable apiTarget='/profits' attributes={tableAttributes} />
        )
    }

    render() {
        return(
            <section className=" py-2">
                <h5 className="mb-3 mt-7">Riwayat Pembayaran</h5>
                {this.renderTable()}
            </section>
        )
    }
}

export default DataTablePayout;