import React, { Component } from 'react';
import Text from './../../components/text';
import { Alert } from 'react-bootstrap';
import BgLogin from './../../assets/img/imgLogin.jpg';
import Logo from './../../assets/img/logo-ambassador.svg';
import api from '../../services/apiCaller';
import {BiArrowBack} from 'react-icons/bi';
import { loginService } from '../../services/authServices';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

class Verification extends Component {
    constructor(props){
        super(props);
        this.state = {
            email: '',
            password: '',
            errorMessage: '',
            viewPassword: false,
            error: false
        }
    }

    componentDidMount = () => {
        const { match: { params } } = this.props;
        
        this.confirmationAccountServices(params.verificationCode)
    }

    getGreeting = () => {
        var e = new Date().getHours();

        return e < 12
        ? "Selamat Pagi"
        : e < 16
        ? "Selamat Siang"
        : e < 18 
        ? "Selamat Sore"
        : "Selamat Malam";
    }

    handleViewPassword = (e) => {
        this.setState({
            viewPassword: e.target.checked,
        })
    }

    handleChange = (e) => {
        this.setState({
            [e.target.type]:e.target.value
        })
    }
    
    handleSubmit = (e) => {
        e.preventDefault();
        
        this.handleLogin(this.state.email, this.state.password);
    }

    confirmationAccountServices = async (verificationCode) => {
        await api.put(`/agent/confirm/${verificationCode}`, {
            verificationCode: verificationCode
        });
    }
    
    handleLogin = async (email, password) => {
        const doLogin = await loginService(email, password);

        if(doLogin.error || doLogin.response) {

            this.setState({
                errorMessage: doLogin.response.data.message,
                error: true
            })

            return Swal.mixin({
                    // toast: true,
                    position: 'center',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                }).fire({
                    icon: 'warning',
                    title: "Oops",
                    text: this.state.errorMessage,
                });
        }

        return (window.location.href = '/dashboard');
        
    }

    handleForgot = async (e) => {
        e.preventDefault();

        return (window.location.href = '/forgot-password');
    }

    handleAlert = ( style, message ) => {
        return <Alert variant="warning" className={style}>{message}</Alert>
    }
    
    render() {
        const bgLogin = {
            background: 'url(' + BgLogin + ') no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            boxShadow: 'inset 0px 4px 15px rgba(0, 0, 0, 0.25)'
        }

        return(
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-7 d-none d-md-block bg-cover bg-primary" style={bgLogin}>
                    </div>
                    <div className="col-lg-5">
                        <div className="row align-items-center min-vh-100 bg-white">
                            <div className="col-md-7 col-lg-8 mx-auto">
                                <img alt="logo_vox" src={Logo} className="pb-4 mb-1" />
                                <h4 className="mb-1">
                                    Akun sudah terverifikasi
                                </h4>
                                <Text className='text-secondary'>Klik tombol dibawah untuk masuk</Text>
                                <div className="mt-4">
                                    <div className="d-flex justify-content-between mt-5">
                                        <Link className="w-100 btn btn-primary" to="/login">
                                            <BiArrowBack /> Ke Halaman Masuk
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Verification;