import React, { Component } from 'react';

class Alert extends Component {
    
    dismissAlert = () => {
        return <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>;
    }
    
    render() {
        return(
            <div className={"alert alert-" + this.props.className} role="alert">
                {this.props.children}
                {/* {console.log(this.props.dismissAlert)} */}
                {this.props.dismissable === "true" ? this.dismissAlert() : "" }
            </div>
        )
    }
}

export default Alert;