import React, { Component } from 'react';
import Header from '../../components/header/Header';
import SideMenuProfile from './components/SideMenuProfile';
import PersonalInfoForm from './components/PersonalInfoForm';
import { Container, Card, Row, Col } from 'react-bootstrap';


class AgentProfile extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }


    render() {
        return(
            <>
                <Header />
                <Container style={{ marginTop: 45 }}>
                    <Card className="border">
                        <Card.Body>
                            <Row>
                                <Col xs={12} md={4} className="pr-md">
                                    <SideMenuProfile />
                                </Col>
                                <Col xs={12} md={8} className="border-left">
                                    <PersonalInfoForm />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Container> 
            </>
        )
    }
}

export default AgentProfile;