import React, { Component } from 'react';
import {Row, Col} from 'react-bootstrap';
import CardComponent from '../../../components/card/CardComponent';
import {BsPeople, BsPersonCheck, BsLink} from 'react-icons/bs';
import {FiUserPlus, FiUserX, FiUserCheck, FiUser} from 'react-icons/fi'


class AgentAchievement extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    showCardAchivement = (agentAchievement,colorAndIcon) => {
        let tagCard = [];
        const isNotNull = this.props.referralData.totalReferralSendThisYear !== undefined;

        agentAchievement.forEach((v,k) => {
            let keyComponent = `agent-${k}`;
            tagCard = [...tagCard, (<Col sm={12} lg={6} className="mb-4" key={keyComponent}>
                <CardComponent bgColor={colorAndIcon[k].color} icon={colorAndIcon[k].icon}>
                    <span>
                        {v.label}
                    </span>
                    <div className="d-flex align-items-baseline">
                        <h1>
                            {isNotNull ? v.value : this.props.loader}
                        </h1>
                        <h5 className="ml-1">
                            {v.unit}
                        </h5>
                    </div>
                </CardComponent>
            </Col>)]
        });

        // console.log(tagCard)

        return tagCard

    }
    
    
    render() {
        const agentAchievement = [
            { label: "Referral Dikirim", value: this.props.referralData.totalReferralSendThisYear, unit: "referral(s)" },
            { label: "Referral Terkonfirmasi", value: this.props.referralData.totalReferralConfirmedThisYear, unit: "referral(s)" },
            { label: "Referral Sukses", value: this.props.referralData.totalReferralSuccessThisYear, unit: "referral(s)" },
            { label: "Referral Ditolak", value: this.props.referralData.totalReferralRejectedThisYear, unit: "referral(s)" },
        ]

        const colorAndIcon = [
            { color: "linear-gradient(180deg, rgb(133 101 224) 0%, rgb(241 128 194) 148.04%)", icon: <FiUserPlus /> },
            { color: "linear-gradient(180deg, rgb(157, 128, 241) 0%, #1aabd9 148.04%)", icon: <FiUser /> },
            { color: "linear-gradient(180deg, #00866A  0%, #13a96c 148.04%)", icon: <FiUserCheck /> },
            { color: "linear-gradient(180deg, #C34881 0%, #e54a5f 148.04%)", icon: <FiUserX /> },
        ]

        return(
            <Row>
                {this.showCardAchivement(agentAchievement,colorAndIcon)}
            </Row>
        )
    }
}

export default AgentAchievement;