import React, { Component } from 'react';
import { Table, Form, Row, Col, Button, Modal } from 'react-bootstrap';
import { IoClose } from 'react-icons/io5';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import { FiExternalLink } from 'react-icons/fi'
import "./DataTable.css"
import api from '../../services/apiCaller'
import Loader from 'react-loader-spinner';
import 'react-vertical-timeline-component/style.min.css';
import moment from 'moment';

class DataTable extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            isLoadingTable: true,
            tableLimit: 10,
            currentPage: 1,
            totalPage: 1,
            firstPage: true,
            lastPage: false,
            tableData: {},
            statusRoom: {},
            max: 0,
            modal: {
                show: false,
                data: {}
            },
            followUp: []
        }

    }

    componentDidMount = () => {
        this._callApi()
    }

    _callApi = async () => {
        await this.setState({
            isLoadingTable: true
        });

        const result = await api.get(this.props.apiTarget, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            },
            params: {
                page: this.state.currentPage,
                limit: this.state.tableLimit
            }
        });

        this.setState({
            isLoadingTable: false,
            totalData: result.data.total,
            tableData: result.data,
            totalPage: result.data.totalPage,
            currentPage: result.data.currentPage
        })
    }

    showTableData = () => {
        let raw = [];

        (this.state.tableData.data).map((v, i) => {
            const Td = [];

            (this.props.attributes).map((aV, aI) => {


                let value = <td key={aI} style={{ verticalAlign: "middle", fontSize: 14 }}>{aV.transform ? aV.transform(v[aV.name], aI) : v[aV.name]}</td>

                if(aV.label === "Nama") {
                    value = <td key={aI} style={{ verticalAlign: "middle", fontSize: 14 }}>{v.firstName + " " + v.lastName}</td>
                }

                if(aV.label === "Akun Bank") {
                    value = <td key={aI} style={{ verticalAlign: "middle", fontSize: 14 }}>{v.bankName + "-" + v.bankAccount}</td>
                }

                if(aV.label === ""){
                    value = <td key={aI} style={{ verticalAlign: "middle", textAlign: "right" }}><Button variant={"info"} onClick={() => this.showModalData(v)} style={{ fontSize: 14}}><FiExternalLink/> Detail</Button></td>
                }

                if(aV.name === "download") value = <td key={aI} style={{ verticalAlign: "middle", textAlign: "right" }}><a href={v.filePath} target="_blank" className="btn btn-info" style={{ fontSize: 14}} rel="noreferrer"><FiExternalLink/> View Promo</a></td>

                return Td.push(
                    value
                )
            })
                        
            return raw.push(<tr style={{ cursor: "pointer" }} key={i}>
                {Td}
            </tr>)
        })

        return raw;
    }

    parseHeaderColumn = () => {
        const headerData = [];

        (this.props.attributes).map((v, i) => {
            return headerData.push(<th key={i} style={{ fontSize: 14 }}>{v.label}</th>)
        });

        return (<tr>
            {headerData}
        </tr>);
    }

    parsePagination = () => {
        const options = [];

        for (let i = 1; i <= this.state.totalPage; i++) {
            options.push(<option>{i}</option>);
        }

        return options;
    }

    onPageChanged = async (e) => {
        await this.setState({
            currentPage: e.target.value
        })

        return this._callApi()
    }

    showModalData = async (data) => {
        const followUp = await api.get('referral/' + data.id, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        });

        this.setState({
            modal: { show: true, data: data },
            followUp: followUp.data.followUpLogs,
        })

    }

    modalContent = (data) => {

        // let status;
        // switch (data.status) {
        //     case '0':
        //         status = 'Uncontacted'
        //         break;
        //     case '1':
        //         status = 'Contacted'
        //         break;
        //     case '2':
        //         status = 'No Response'
        //         break;
        //     case '3':
        //         status = 'Not Interested'
        //         break;
        //     case '4':
        //         status = 'Transaction In Process'
        //         break;
        //     case '5':
        //         status = 'Failed'
        //         break;
        //     case '6':
        //         status = 'Rejected'
        //         break;
        //     default:
        //         status = '-'
        //         break;
        // }

        return (<div>
                    <Row className="">
                        <Col lg={6}>
                            <Row>
                                <Col lg={12} className="mb-1">
                                    <Form.Label>
                                        Nama : <br />
                                        <span className="mb-0" style={{ fontWeight: 300 }}>
                                            { `${data.firstName} ${data.lastName}` }
                                        </span>
                                    </Form.Label>
                                </Col>
                                <Col lg={12} className="mb-1 mb-lg-0">
                                    <Form.Label>
                                        Phone : <br />
                                        <span className="mb-0" style={{ fontWeight: 300 }}>
                                            { `${data.phone}` }
                                        </span>
                                    </Form.Label>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={6} className="mb-1">
                            <Form.Label>
                                Email : <br />
                                <span className="mb-0" style={{ fontWeight: 300 }}>
                                    { `${data.email}` }
                                </span>
                            </Form.Label>
                        </Col>
                    </Row>
                    {
                        data.reasonRejected && 
                        <>
                            <hr />
                            <Row>
                                <Col lg={12} className="mb-1">
                                    <Form.Label>
                                        Reason Rejected : <br />
                                        <span className="mb-0" style={{ fontWeight: 300 }}>
                                            { `${data.reasonRejected}` }
                                        </span>
                                    </Form.Label> 
                                </Col>
                            </Row>
                        </>
                    }
                    <hr />
                    <Row>
                        <Col xs={12} className="mb-3">
                            Timeline Follow Up
                        </Col>
                        <Col>
                            {this.state.followUp ? this.timeLineReferral(this.state.followUp) : ''}
                        </Col>
                    </Row>
                </div>)
    }

    timeLineReferral = (data) => {
        let tagTimeline = [];

        data.forEach((v,k) => {
            tagTimeline.push(<VerticalTimeline layout={"1-column-left"}>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        date={`${moment(v.createdAt).format("DD MMM YYYY HH:MM")}`}
                    >
                        {/* <label className="vertical-timeline-element-title">{v.comment}</label> */}
                        <p>
                        {v.comment}
                        </p>
                    </VerticalTimelineElement>
                </VerticalTimeline>)
        });

        return tagTimeline
    }

    // downloadFile = (data, event) => {
    //     fetch(event.target.id, {
    //         method: "GET",
    //         headers: {}
    //     })
    //     .then((res) => {
    //         res.arrayBuffer().then((buffer) => {
    //             const url = URL.createObjectURL(new Blob([buffer]));
    //             const link = document.createElement("a");

    //             link.href = url;
    //             link.setAttribute("download", data.file);

    //             document.body.appendChild(link);
    //             link.click();
    //         });
    //     })
    //     .catch((err) => {
    //         console.log(err);
    //     })
        
    // }

    showLoader = () => {
        return (
            <div style={{height: 150}} className="text-center pt-4">
                <Loader type="TailSpin" color="#00BFFF" height={80} width={80} />
            </div>
        )
    }

    render() { 
            // const statusData = {0:{text:'empty', color: 'secondary'}, 1:{text:'booked', color: 'warning'}, 2:{text:'occupied', color: 'success'}, 3:{text:'occupied', color: 'success'}, 4:{text:'occupied', color: 'success'}, 5:{text:'occupied', color: 'success'}, 6:{text:'disabled', color: 'light'}}
        return (
            <div className="my-5" style={{ overflow: "visible", boxShadow: "0px 5px 20px -15px rgba(3, 34, 46, 0.5)" }}>
                <Row>
                    <Col className="px-0 mx-2" style={{ overflow: "auto" }}>
                        {this.state.isLoadingTable ? 
                        this.showLoader() : 
                        <Table className="mb-0">
                            <thead>
                                {this.parseHeaderColumn()}
                            </thead>
                            <tbody>
                                {this.showTableData()}
                            </tbody>
                        </Table>}
                    </Col>
                </Row>
                <Row className='justify-content-end align-content-center m-0' style={{ fontSize: 13, opacity: ".6", padding: 5, background: "#e6ebf7", borderRadius: "0 0 10px 10px" }}>
                    <Col xs={12} className=''>
                        <Row className="justify-content-md-end justify-content-center">
                                <span 
                                className="pagination-label my-auto d-md-block d-none">
                                    Rows per page:
                                </span>
                                <div className="test my-auto d-md-block d-none" style={{ 
                                        width: 44,
                                        height:22, }}>
                                    <Form.Control as='select'
                                    className="select-pagination-row py-0 my-auto"
                                    style={{  
                                        width: "auto",
                                        height: 22,
                                        fontSize: 13,
                                        background: "white",
                                        border:0
                                    }} onChange={async (e) => {
                                        await this.setState({
                                            tableLimit: e.target.value,
                                            dataTo: e.target.value,
                                            currentPage: 1,
                                            dataFrom: 1
                                        });

                                        this._callApi()
                                    }} value={this.state.tableLimit}>
                                        <option>10</option>
                                        <option>15</option>
                                        <option>25</option>
                                        <option>35</option>
                                        <option>50</option>
                                    </Form.Control>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                        <path d="M7 10l5 5 5-5z"></path>
                                        <path d="M0 0h24v24H0z" fill="none"></path>
                                    </svg>
                                </div>
                            
                                <span className="my-auto d-md-block d-none mx-3">
                                {(this.state.tableData.currentPage * this.state.tableData.limit) - this.state.tableData.limit + 1} - {
                                    this.state.tableData.currentPage * this.state.tableData.limit > this.state.tableData.total 
                                    ? this.state.tableData.total
                                    : this.state.tableData.currentPage * this.state.tableData.limit
                                } of {
                                    this.state.tableData.total
                                }
                                </span>
                            
                                <div className='d-flex align-items-center'>
                                    {/* Button skipPrev Data */}
                                    <Button variant="white" className="rounded-circle p-0" style={{ width: 40, height: 40, border: 0 }}
                                    disabled={this.state.currentPage - 1 === 0 ? true : false}
                                    onClick={async () => {
                                        const firstPage = this.state.currentPage - this.state.currentPage;

                                        await this.setState({
                                            currentPage: firstPage,
                                        })

                                        return this.onPageChanged({target: {
                                            value: firstPage
                                        }})
                                    }}>
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            aria-hidden="true"
                                            role="presentation">
                                                <path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z"></path>
                                                <path fill="none" d="M24 24H0V0h24v24z"></path>
                                        </svg>
                                    </Button>
                                    {/* Button Prev Data */}
                                    <Button variant="white" className="rounded-circle p-0" style={{ width: 40, height: 40, border: 0 }} 
                                    disabled={this.state.currentPage - 1 === 0 ? true : false}
                                    onClick={async () => {
                                        const previousPage = this.state.currentPage - 1 === 0 ? 1 : this.state.currentPage - 1;

                                        return this.onPageChanged({target: {
                                            value: previousPage
                                        }})
                                    }}>
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            aria-hidden="true"
                                            role="presentation">
                                                <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
                                                <path d="M0 0h24v24H0z" fill="none"></path>
                                        </svg>
                                    </Button>

                                    <div className="d-md-none test my-auto" style={{ 
                                            width: 44,
                                            height:22, }}>
                                        <Form.Control as='select'
                                        className="select-pagination-row py-0 my-auto"
                                        style={{  
                                            width: "auto",
                                            height: 22,
                                            fontSize: 13,
                                            background: "white",
                                            border:0
                                        }} onChange={async (e) => {
                                            await this.setState({
                                                tableLimit: e.target.value,
                                                dataTo: e.target.value,
                                                currentPage: 1,
                                                dataFrom: 1
                                            });

                                            this._callApi()
                                        }} value={this.state.tableLimit}>
                                            <option>10</option>
                                            <option>15</option>
                                            <option>25</option>
                                            <option>35</option>
                                            <option>50</option>
                                        </Form.Control>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                            <path d="M7 10l5 5 5-5z"></path>
                                            <path d="M0 0h24v24H0z" fill="none"></path>
                                        </svg>
                                    </div>

                                    {/* Button Next Data */}
                                    <Button variant="white" className="rounded-circle p-0" style={{width: 40, height: 40, border: 0, fontSize: 18 }}
                                    disabled={this.state.currentPage === this.state.totalPage ? true : false}
                                    onClick={async () => {
                                        return this.onPageChanged({target: {
                                            value: this.state.currentPage + 1
                                        }})
                                    }}>
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            aria-hidden="true"
                                            role="presentation">
                                                <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
                                                <path d="M0 0h24v24H0z" fill="none"></path>
                                        </svg>
                                    </Button>
                                    {/* Button skipNext Data */}
                                    <Button variant="white" className="rounded-circle p-0" style={{width: 40, height: 40, border: 0, fontSize: 18 }}
                                    disabled={this.state.currentPage === this.state.totalPage ? true : false}
                                    onClick={async () => {
                                        return this.onPageChanged({target: {
                                            value: this.state.totalPage
                                        }})
                                    }}><svg xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            aria-hidden="true"
                                            role="presentation">
                                                <path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z"></path>
                                                <path fill="none" d="M0 0h24v24H0V0z"></path>
                                        </svg>
                                    </Button>
                                </div>
                        </Row>
                    </Col>
                </Row>
                
                <Row>
                    <Modal show={this.state.modal.show} centered>
                        <Modal.Header>
                            <Modal.Title className="align-content-between w-100">
                                {this.state.modal.show ? <div className="d-flex justify-content-between">
                                    <span>
                                        Detail
                                    </span>
                                    <div
                                        className="btn"
                                        onClick={() => this.setState({ modal: { show: false } })}><IoClose />
                                    </div>
                                </div> : ""}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {this.state.modal.show ? this.modalContent(this.state.modal.data) : ''}
                        </Modal.Body>
                    </Modal>
                </Row>
            </div>
        );
    }
}

export default DataTable;