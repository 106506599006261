import React, { Component } from 'react';
import { Alert } from 'react-bootstrap';
import EmailForm from './components/emailForm';
import NewPasswordForm from './components/newPasswordForm';
import BgLogin from '../../assets/img/imgLogin.jpg';


class Forgot extends Component {
    constructor(props){
        super(props)
        this.state={
            token : null
        }
        this.handleAlert = this.handleAlert.bind(this);
    }

    handleAlert = ( style, message ) => {
        return <Alert variant="warning" className={style}>{message}</Alert>
    }

    componentDidMount = () => {
        const { match: { params } } = this.props;

        this.setState({
            token: params.token
        })
    }

    render() {
        const bgLogin = {
            background: 'url(' + BgLogin + ') no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            boxShadow: 'inset 0px 4px 15px rgba(0, 0, 0, 0.25)'
        }

        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-7 d-none d-md-block bg-cover bg-primary" style={bgLogin}>
    
                    </div>
                    <div className="col-lg-5">
                        {window.location.pathname === "/password/forgot" ? 
                            <EmailForm handleAlert={this.handleAlert} /> : 
                            this.state.token && <NewPasswordForm token={this.state.token} />
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default Forgot;