import React, { Component } from 'react';
import api from '../../services/apiCaller';
import Header from '../../components/header/Header';
import { Container } from 'react-bootstrap';
import TableProduct from './components/tableProduct';

class Promo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            referalData: null,
            isLoading: true,
        }
    }

    componentDidMount = () => {
        this.getReferalData();
    }

    getReferalData = async () => {
        const fetchData = await api.get(`/referral/code`);

        this.setState({
            referalData:fetchData.data,
            isLoading: false
        })
    }


    showPromo = () => {
        return <>
            <h5 className="mb-4">Promo</h5>
            <TableProduct />
        </>
    }

    render() {
        return (
            <>
                <Header />
                <Container style={{ marginTop: 45 }}>
                    {this.showPromo()}
                </Container>
            </>
        );
    }
}

export default Promo;