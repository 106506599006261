import React, { Component } from 'react';
import api from '../../../services/apiCaller';
import { Col, Row } from 'react-bootstrap';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";


class PromoSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            promoeData: {},
            isLoading: true
        } 
    }

    componentDidMount = () => {
        this.getPromo();
    }

    getPromo = async () => {
        const fetchData = await api.get(`/promo`);

        this.setState({
            promoeData: fetchData.data,
            isLoading: !this.state.isLoading
        })
    }

    showpromolider = (promoeData) => {
        let stackPromo = [];
        let countComponent = 0;
        let keyComponent = `stackPromo${countComponent}`;

        for (let property in promoeData.data) {
            stackPromo = [...stackPromo, (<div className="fill" key={keyComponent}>
              <div className="content-promo" style={{ width: "100%"}}>
                <img src={promoeData.data[`${property}`].filePath} alt={`img-`+ promoeData.data[`${property}`].file} width="100%" height="auto" />\
              </div>
                {/* <a className="content-promo" href={promoeData.data[`${property}`].link} target="_blank" rel="noreferrer" style={{ width: "100%"}}> */}
                {/* </a> */}
            </div>)]
            
            countComponent++;
        }

        return stackPromo;

    }
    
    render() {
        const settings = {
            dots: true,
            infinite: true,
            centerMode: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            centerPadding: "50px",
            // autoplay: true,
            // autoplaySpeed: 2000,
            pauseOnHover: true
        };

        return(
            <Row className="mb-4">
                <Col xs={12}>
                    <Slider {...settings}>
                        {this.showpromolider(this.state.promoeData)}
                    </Slider>
                </Col>
            </Row>
        )
    }
}

export default PromoSection;