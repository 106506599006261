import React, { Component } from 'react';
import Swal from 'sweetalert2';
import DataTable from '../../../components/DataTable/DataTable';


class UniqueCode extends Component {
    constructor(props) {
        super(props);
        this.state = {
            
        }
    }

    copyToClipboard = async() => {
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        try {
            await navigator.clipboard.writeText(this.props.data.uniqueCode);

            Toast.fire({
                icon: 'success',
                title: 'Kode Unik Tersalin'
            })
        } catch (error) {
            console.log(error);
        }
    }

    renderTable(){

        let tableAttributes = [
            {label: 'Promo', name: 'name'},
            {label: '', name: 'download'},
        ];

        return (
            <DataTable apiTarget='/brochure' attributes={tableAttributes} />
        )
    }
    
    render() {
        return(
            <>
                {this.renderTable()}
            </>
        )
    }
}

export default UniqueCode;