import React from 'react';
import { BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';
import './App.css';
import Login from './pages/Login';
import Register from './pages/Register/Individu';
import RegisterCorporat from './pages/Register/Corporat';
import PublicRoute from './routes/PublicRoute';
import BankAccount from './pages/AgentProfile/BankAccount';
import ContractInfo from './pages/AgentProfile/ContractInfo';
import ChangePassword from './pages/AgentProfile/ChangePassword';
import Promo from './pages/Promo';
import ChangePassword1 from './pages/ChangePassword';
import Verification from './pages/Verification';
import Dashboard from './pages/Dashboard';
import Profits from './pages/Profits';
import AgentProfile from './pages/AgentProfile';
import Referrals from './pages/Referrals';
import PrivateRoute from './routes/PrivateRoute';
import ForgotPassword from './pages/ForgotPassword';
import Page404 from './pages/Page404';
import Auth from './Auth';

function App() {

    const isAuthenticated = Auth.isAuthenticated();

    return (
        <Router>
            <Switch>
                <PublicRoute exact path='/login' component={Login} isAuthenticated={isAuthenticated} />
                <PublicRoute exact path='/register' component={Register} isAuthenticated={isAuthenticated} />
                <PublicRoute exact path='/register/corporate' component={RegisterCorporat} isAuthenticated={isAuthenticated} />
                <PublicRoute exact path='/accounts/verification/:verificationCode' component={Verification} isAuthenticated={isAuthenticated} />
                <PublicRoute exact path='/password/:token' component={ForgotPassword} isAuthenticated={isAuthenticated} />
                <PublicRoute exact path='/oops' component={Page404} />
                {/* <PublicRoute exact path='/password/forgot/:token' component={ForgotPassword} isAuthenticated={isAuthenticated} / */}
                <PrivateRoute exact path='/promo' component={Promo} isAuthenticated={isAuthenticated} />
                <PrivateRoute exact path='/accounts/edit/' component={AgentProfile} isAuthenticated={isAuthenticated} />
                <PrivateRoute exact path='/accounts/bank/' component={BankAccount} isAuthenticated={isAuthenticated} />
                <PrivateRoute exact path='/accounts/contract/' component={ContractInfo} isAuthenticated={isAuthenticated} />
                <PrivateRoute exact path='/accounts/password/' component={ChangePassword} isAuthenticated={isAuthenticated} />
                <PrivateRoute exact path='/change-password' component={ChangePassword1} isAuthenticated={isAuthenticated} />
                <PrivateRoute exact path='/dashboard' component={Dashboard} isAuthenticated={isAuthenticated} />
                <PrivateRoute exact path='/profits' component={Profits} isAuthenticated={isAuthenticated} />
                <PrivateRoute exact path='/referral' component={Referrals} isAuthenticated={isAuthenticated} />
                <Redirect to={isAuthenticated ? '/dashboard' : '/login'} />
            </Switch>
        </Router>
    );
}

export default App;
