import React from 'react';
import Swal from 'sweetalert2';
import {Form, Button, Row, Col } from 'react-bootstrap';
import {Input, Textarea, Select} from '../../components/form';
import Spinner from 'react-bootstrap/Spinner';
import api from './../../services/apiCaller';
import { bank } from '../../data/bank';


import './UploadFile.css';

export default class UploadFile extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            formData: {
                address: "",
                bankName: "",
                bankBranch: "",
                bankAccount: "",
                idCard: "",
                taxCard: ""
            },
            errors: {},
            imgSrc: {},
            isLoading: false
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.uploadFileService = this.uploadFileService.bind(this);
    }

    handleChange(e){
        let formData = this.state.formData;
        let imgSrc = this.state.imgSrc;
        let dataForm = {};
        let reader = new FileReader();

        if (e.target.files) {
            dataForm = e.target.files[0];
            reader.readAsDataURL(e.target.files[0]);
        }else{
            dataForm = e.target.value;
        }
        
        formData[e.target.name] = dataForm;
        this.setState({formData});

        reader.onloadend = (elementReader) => {
            imgSrc[e.target.name] = [reader.result];

            this.setState({
                imgSrc
            })
        }

    }

    validate = () => {
        let formData = this.state.formData;
        let objErrors = {
            address: "Silakan, masukan alamat anda.",
            bankName: "Silakan, pilih bank anda.",
            bankBranch: "Silakan, masukan cabang bank.",
            bankAccount: "Silakan, masukan akun bank anda.",
            idCard: "Silakan, upload KTP anda.",
            taxCard: "Silakan, upload NPWP anda.",
        };

        if (this.props.agentData.address) {
            formData['address'] = this.props.agentData.address;
        }

        let errors = {};

        let isValid = true;

        for (let property in formData) {
            // const element = array[index];
            if (formData[`${property}`] === "" || formData[`${property}`] === null || formData[`${property}`] === undefined) {
                isValid = false;
                errors[`${property}`] = objErrors[`${property}`];          
            }

        }

        this.setState({
            errors: errors
        })

        return isValid;
    }

    handleSubmit = (e) => {
        e.preventDefault();
        if (this.validate()) {
            this.uploadFileService(this.state.formData);
        }
    }

    uploadFileService = async (data) => {
        const formData = new FormData();

        formData.append('idCard', data.idCard);
        formData.append('taxCard', data.taxCard);
        formData.append('address', data.address);
        formData.append('bankName', data.bankName);
        formData.append('bankBranch', data.bankBranch);
        formData.append('bankAccount', data.bankAccount);

        try {
            await api.post('agent/upload-paper', formData)
            .then((res) =>{
                Swal.mixin({
                    toast: true,
                    position: 'center',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                }).fire({
                    icon: 'success',
                    title: 'File terkirim'
                }).then((res => {
                    if (res) {
                        document.location.replace("/dashboard");
                    }
                }))
            })

        }catch(err) {
            console.log(err);
        }
}

    render(){
        let error = {
            fontSize: 10,
            position: "absolute",
            bottom: 0
        };

        let errorSp = {
            fontSize: 10,
            position: "absolute",
            bottom: -20
        };
        

        let loadingStatus = this.state.isLoading;
        let button = !loadingStatus ? 'Kirim Data' : (<Spinner animation="border" variant="light" as="span" size="sm" role="status"/>)
        return (
            <>  
                <Form>
                    <Form.Group style={{ position: "relative", }}>
                        <Textarea
                            label="Alamat"
                            formName="address"
                            className={(this.state.error ? 'is-invalid' : '')}
                            value={this.props.agentData.address ? this.props.agentData.address : this.state.address}
                            onChange={(e) => this.handleChange(e)}
                        ></Textarea>
                        <div className="text-danger" style={errorSp}>{this.state.errors.address && this.state.errors.address}</div>
                    </Form.Group>
                    <Form.Group>
                        <Row>
                            <Col sm={12} className="mt-3">
                                <h6 className="font-weight-lighter">Rekening Bank</h6>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <Select
                                    type="text"
                                    formName="bankName"
                                    className={(this.state.error ? 'is-invalid' : '')}
                                    value={this.state.bankName}
                                    onChange={e => this.handleChange(e)}
                                    defaultValue=""
                                >
                                    <option value="">Pilih Bank</option>
                                    {
                                        (Object.values(bank)).map(bank => {
                                            return <option key={`option-${bank.name}`} value={bank.name}>{bank.name}</option>
                                        })
                                    }
                                </Select>
                                <div className="text-danger" style={error}>{this.state.errors.bankName && this.state.errors.bankName}</div>
                            </Col>
                            <Col sm={12} lg={6}>
                                <Input
                                    type="text"
                                    label="No Rekening"
                                    formName="bankAccount"
                                    className={(this.state.error ? 'is-invalid' : '')}
                                    value={this.state.bankAccount}
                                    onChange={(e) => this.handleChange(e)}
                                ></Input>
                                <div className="text-danger" style={error}>{this.state.errors.bankAccount && this.state.errors.bankAccount}</div>
                            </Col>
                            <Col sm={12} lg={6}>
                                <Input
                                    type="text"
                                    label="Kantor Cabang"
                                    formName="bankBranch"
                                    className={(this.state.error ? 'is-invalid' : '')}
                                    value={this.state.bankBranch}
                                    onChange={(e) => this.handleChange(e)}
                                ></Input>
                                <div className="text-danger" style={error}>{this.state.errors.bankBranch && this.state.errors.bankBranch}</div>
                            </Col>
                        </Row>
                    </Form.Group>
                    <Form.Group style={{ position: "relative", }}>
                        <Form.File id="idCard" label="KTP" name="idCard" accept="image/png, image/jpeg" onChange={this.handleChange} />
                        <img src={this.state.imgSrc.idCard} alt="idCard-img" className={`w-100 ${!this.state.formData.idCard ? "d-none" : ""}`} />
                        <div className="text-danger" style={errorSp}>{this.state.errors.idCard && this.state.errors.idCard}</div>
                    </Form.Group>
                    <Form.Group style={{ position: "relative", }}>
                        <Form.File id="taxCard" label="NPWP" name="taxCard" accept="image/png, image/jpeg" onChange={this.handleChange} />
                        <img src={this.state.imgSrc.taxCard} alt="taxCard-img" className={`w-100 ${!this.state.formData.taxCard ? "d-none" : ""}`} />
                        <div className="text-danger" style={errorSp}>{this.state.errors.taxCard && this.state.errors.taxCard}</div>
                    </Form.Group>
                    <Button variant="primary" type="submit" className="mt-5 btn-block" onClick={this.handleSubmit}>
                        {button}
                    </Button>
                </Form>
            </>
        );
    }

}