const bank = [
    {
        "name": "BANK BRI",
        "code": "002"
    },
    {
        "name": "BANK CIMB NIAGA",
        "code": "002"
    },
    {
        "name": "BANK MANDIRI",
        "code": "022"
    },
    {
        "name": "BANK BNI",
        "code": "009"
    },
    {
        "name": "BANK BNI SYARIAH",
        "code": "427"
    },
    {
        "name": "BANK DANAMON",
        "code": "011"
    },
    {
        "name": "PERMATA BANK",
        "code": "013"
    },
    {
        "name": "BANK BCA",
        "code": "014"
    },
    {
        "name": "BANK BII",
        "code": "016"
    },
    {
        "name": "BANK PANIN",
        "code": "019"
    },
    {
        "name": "BANK NISP",
        "code": "028"
    },
    {
        "name": "BANK DBS INDONESIA",
        "code": "046"
    },
    {
        "name": "STANDARD CHARTERED BANK",
        "code": "050"
    },
    {
        "name": "BANK UOB INDONESIA",
        "code": "058"
    },
    {
        "name": "ANZ PANIN BANK",
        "code": "061"
    },
    {
        "name": "BANK JABAR",
        "code": "110"
    },
    {
        "name": "BANK DKI",
        "code": "111"
    },
    {
        "name": "BANK JATENG",
        "code": "113"
    },
    {
        "name": "BANK JATIM",
        "code": "114"
    },
    {
        "name": "BANK TABUNGAN NEGARA (PERSERO)",
        "code": "200"
    },
    {
        "name": "BANK TABUNGAN PENSIUNAN NASIONAL",
        "code": "213"
    },
    {
        "name": "BANK MAYBANK INDOCORP",
        "code": "947"
    },
    {
        "name": "BANK OCBC – INDONESIA",
        "code": "948"
    },
    {
        "name": "BANK COMMONWEALTH",
        "code": "950"
    },
    {
        "name": "BANK BJB SYARIAH",
        "code": "425"
    }
];

export { bank }