import React, { Component } from 'react';
import {Button, Card, FormGroup} from 'react-bootstrap';
import Logo from '../../../assets/img/logo-ambassador.svg';
import Text from '../../../components/text';
import {Input} from '../../../components/form';
import {FiSend} from 'react-icons/fi';
import {BiArrowBack} from 'react-icons/bi';
import { Link } from 'react-router-dom';
import api from '../../../services/apiCaller';
import Swal from 'sweetalert2';


class EmailForm extends Component {
    constructor(props){
        super(props)
        this.state={
            email: '',
            errors: {}
        }
    }

    validate = () => {
        let email = this.state.email;
        let errors = {};
        let isValid = true;

        if (!email) {
            isValid = false;
            errors["email"] = "Tolong isi kolom email."
        }

        this.setState({
            errors: errors
        })

        return isValid;
    }

    handleChange = (e) => {
        this.setState({
            [e.target.type]:e.target.value
        })
    }
    
    sendMailService = async (email) => {

        try{
            const doForgotPassword = await api
                .post(`${process.env.REACT_APP_API_URL}/forgotPassword`,
                {
                    email: email
                })

            if (doForgotPassword.status === 200) {
                Swal.mixin({
                    // toast: true,
                    position: 'center',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                }).fire({
                    icon: 'success',
                    title: 'Tautan berhasil dikirimkan'
                }).then((res => {
                    if (res) {
                        document.location.replace("/login");
                    }
                }))
            }
        }catch(e){
            // console.log(e.response.data);
            if (!e.response.data.status) {
                const errors = this.state.errors;
                errors["email"] = "Email tidak terdaftar";

                this.setState({
                    errors
                })

                Swal.mixin({
                    // toast: true,
                    position: 'center',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                }).fire({
                    icon: 'warning',
                    title: 'Email tidak terdaftar'
                })
            }
        }
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        
        const email = this.state.email;

        if (this.validate()) {
            this.sendMailService(email);
        }
        
    }

    render(){
        let formGroup = {
            marginBottom: "2.5rem",
            position: "relative",
        }
        let error = {
            fontSize: 10,
            position: "absolute",
            bottom: -20
        }
        return(
            <div className="row align-items-center min-vh-100 bg-white">
                <div className="col-md-7 col-lg-8 mx-auto">
                    <img alt="logo_vox" src={Logo} className="pb-4 mb-1" />
                    <h4 className="mb-1">
                        Kesulitan Login?
                    </h4>
                    <Text className='text-secondary'>Masukkan email anda dan kami akan mengirimi Anda tautan untuk kembali ke akun Anda.</Text>
                    <div className="mt-4">
                        <FormGroup style={formGroup}>
                            <Input 
                                type="email"
                                label="Email"
                                formName="email"
                                className={(this.state.errors.email ? 'is-invalid' : '')}
                                value={ this.state.email }
                                onChange={(e) => this.handleChange(e)}
                            />
                            <div className="text-danger" style={error}>{this.state.errors.email && this.state.errors.email}</div>
                        </FormGroup>
                        <div className="d-flex justify-content-between mt-5">
                            <Button className="w-100" variant='primary' onClick={(e) => this.handleSubmit(e)}>
                                <FiSend /> Kirim Tautan Masuk
                            </Button>
                        </div>
                        <div className="d-flex justify-content-between my-1">
                            <hr className="w-100" style={{ borderTop: '1px solid rgb(173, 173, 173)' }} />
                        </div>
                        <div className="d-flex justify-content-center align-items-center mt-3">
                            <Link to="/register">Daftar sebagai agen baru</Link>
                        </div>
                        <div className="text-center mt-7">
                            <Card.Text className="small text-secondary"> 
                                <Link className="btn btn-link" to="/login"><BiArrowBack /> Kembali ke Halaman Masuk</Link>
                            </Card.Text>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default EmailForm;