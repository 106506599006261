import React, { Component } from 'react';
import {Card, Col} from 'react-bootstrap';

class CardComponent extends Component {
    constructor(props){
        super(props);
        this.setState={
            
        }
    }

    render(){
        return(
            <Card className="border" style={{ flexDirection: "row" }}>
                <Col xs={1} className="d-flex justify-content-center align-items-center card-border-left p-0" style={{ background: this.props.bgColor, borderRadius: "0.4rem 0 0 0.4rem"}}>
                </Col>
                <Card.Body className="card-cstm-content border-left-custom">
                    {this.props.children}
                </Card.Body>
                <Col lg={3} className="d-flex justify-content-center align-items-center" style={{ background: this.props.bgColor, borderRadius: "0 0.4rem 0.4rem 0", fontSize: "2rem", color: "white" }}>
                    {this.props.icon}
                </Col>
            </Card>
        )
    }
}

export default CardComponent;